import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import TabItem from '../../infra/TabList/TabItem';

import styles from './ModalActivity.module.scss';

export interface DataObject {
  name: string;
  id: string;
  children?: React.ReactNode;
}

export type ModalActivityProps = {
  data?: Array<DataObject>;
  isOpen: boolean;
  onClose: () => void;
  buttonClose?: boolean;
  handleTabChange: (id: string) => void;
  title?: string;
  subtitle?: string;
  tabActive?: string;
  onClickEdit?: () => void;
  vw?: number;
  vh?: number;
  clickOutsideToClose: boolean;
  hasButtonEdit?: boolean;
  extraContent?: React.ReactNode;
};

const ModalActivity: React.FC<ModalActivityProps> = ({
  isOpen,
  data,
  onClose,
  buttonClose,
  title,
  subtitle,
  tabActive,
  handleTabChange,
  onClickEdit,
  clickOutsideToClose,
  extraContent,
  vw,
  vh,
  hasButtonEdit = false,
}) => {
  const style: React.CSSProperties = {
    width: vw && `${vw}vw`,
    height: vh && `${vh}vh`,
  };

  const onTabChange = (id: string): void => {
    handleTabChange(id);
  };

  return (
    <div
      data-testid="container"
      className={`${styles.container} ${isOpen ? styles.open : ''}`}
    >
      {isOpen && (
        <div className={`${styles.modal}`} role="dialog">
          {buttonClose || title ? (
            <div
              className={`${styles.header} ${title &&
                styles.withTitle} ${buttonClose && styles.withButtonClose}`}
            >
              {!!title && (
                <div className={styles.titleContainer}>
                  <div className={styles.titleRow}>
                    <h4 className={styles.title}>{title}</h4>

                    {hasButtonEdit && (
                      <button
                        onClick={onClickEdit}
                        className={styles.buttonPencil}
                      >
                        <FontAwesomeIcon
                          icon="pencil-alt"
                          size="1x"
                          color="#666"
                        />
                      </button>
                    )}
                  </div>

                  {!!subtitle && <h5>{subtitle}</h5>}
                </div>
              )}

              {buttonClose && (
                <button
                  className={styles.buttonClose}
                  onClick={onClose}
                  data-testid="buttonClose"
                >
                  <FontAwesomeIcon icon="times" size="lg" color="#666" />
                </button>
              )}
            </div>
          ) : null}
          {extraContent && (
            <div className={styles.extraContent}>{extraContent}</div>
          )}
          <div className={styles.content} style={style}>
            <div className={styles.lateralContent}>
              {data &&
                data.map(({name, id}) => (
                  <div
                    className={` ${styles.tabItemWrapper} ${
                      tabActive === id ? styles.active : null
                    }`}
                    key={id}
                    data-testid={id}
                  >
                    <TabItem
                      userRole="userRole"
                      active={tabActive === id}
                      handleClick={(): void => onTabChange(id)}
                      key={id}
                      text={name}
                    />
                  </div>
                ))}
            </div>
            {data &&
              data.map(({children, id}) => (
                <div key={id} className={styles.children}>
                  {tabActive === id ? children : null}
                </div>
              ))}
          </div>
        </div>
      )}
      <div
        data-testid="background"
        className={styles.background}
        onMouseDown={clickOutsideToClose ? onClose : undefined}
        role="presentation"
      />
    </div>
  );
};

export default ModalActivity;
