import React from 'react';

import Button from '../LegacyButton';
import styles from './UniqueForm.module.scss';

type Answer = {
  text: string;
  id: string | number;
};

type ActiveItem = string | number | null;

type UniqueFormProps = {
  title: string;
  subtitle?: string;
  answers: Array<Answer>;
  onSelectItem: (id: ActiveItem) => void;
  activeItem?: ActiveItem;
  submitText?: string;
  onSubmit?(): void;
  onGoBack?(): void;
  isButtonDisabled?: boolean;
};

const UniqueForm: React.FC<UniqueFormProps> = ({
  title,
  answers,
  onSelectItem,
  activeItem,
  subtitle,
  submitText,
  onSubmit,
  onGoBack,
  isButtonDisabled = false,
}) => {
  return (
    <div data-testid="form" className={styles.uniqueForm}>
      <div className={styles.header}>
        <h1>{title}</h1>
        {subtitle && <div dangerouslySetInnerHTML={{__html: subtitle}} />}
      </div>
      {answers.map((answer: Answer, index: number) => (
        <button
          tabIndex={index + 1}
          key={`answer-${answer.id}`}
          className={`
          ${styles.answer} 
          ${activeItem === answer.id && `${styles.active}`}
        `}
          onClick={(): void => {
            onSelectItem(answer.id);
          }}
        >
          <span className={styles.text}>{answer.text}</span>
        </button>
      ))}
      <div className={styles.wrapperSubmit}>
        {onGoBack && (
          <Button userRole="student" kind="secondary" onClick={onGoBack}>
            Voltar
          </Button>
        )}
        {onSubmit && (
          <Button
            userRole="student"
            onClick={onSubmit}
            disabled={isButtonDisabled}
          >
            {submitText}
          </Button>
        )}
      </div>
    </div>
  );
};

export default UniqueForm;
