import React, {HTMLAttributes} from 'react';
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from '@fortawesome/react-fontawesome';
import {Button} from 'components';
import styles from './InlineAlert.module.scss';

export interface InlineAlertProps
  extends Omit<HTMLAttributes<HTMLDivElement>, 'style' | 'className'> {
  backgroundColor?: string;
  mainColor?: string;
  leftIcon?: FontAwesomeIconProps;
  firstRightIcon?: FontAwesomeIconProps;
  secondRightIcon?: FontAwesomeIconProps;
  firstButtonText?: string;
  secondButtonText?: string;
  textColor?: string;
  buttonTextColor?: string;
  onClickFirstButton?: React.MouseEventHandler;
  onClickSecondButton?: React.MouseEventHandler;
}

function InlineAlert({
  leftIcon,
  firstRightIcon,
  secondRightIcon,
  firstButtonText,
  onClickFirstButton,
  secondButtonText,
  onClickSecondButton,
  textColor,
  backgroundColor,
  mainColor,
  buttonTextColor = '#ffffff',
  children,
}: InlineAlertProps): JSX.Element {
  return (
    <div
      data-testid="inline-alert"
      className={`
        ${styles.inlineAlertWrapper}
      `}
      style={{
        backgroundColor,
        borderColor: mainColor,
        color: textColor,
        boxShadow: mainColor,
      }}
    >
      <section className={styles.section}>
        {leftIcon && (
          <FontAwesomeIcon size="lg" color={mainColor} {...leftIcon} />
        )}
        {children}
      </section>

      <section className={styles.section}>
        {firstRightIcon && (
          <FontAwesomeIcon size="lg" color={mainColor} {...firstRightIcon} />
        )}
        {secondRightIcon && (
          <FontAwesomeIcon size="lg" color={mainColor} {...secondRightIcon} />
        )}
        {firstButtonText && (
          <Button
            variant="secondary"
            text={firstButtonText}
            onClick={onClickFirstButton}
          />
        )}
        {secondButtonText && (
          <Button
            variant="primary"
            textColor={buttonTextColor}
            borderColor={mainColor}
            backgroundColor={mainColor}
            text={secondButtonText}
            onClick={onClickSecondButton}
          />
        )}
      </section>
    </div>
  );
}

export default InlineAlert;
