import React from 'react';
import styles from './LoadingBox.module.scss';

export interface LoadingBoxProps {
  width: number | string;
  height: number | string;
  borderRadius?: number;
}

function LoadingBox({
  height,
  width,
  borderRadius = 0,
}: LoadingBoxProps): JSX.Element {
  return (
    <div
      className={styles.shimmer}
      data-testid="loading-row"
      style={{width: '100%', maxWidth: width, height, borderRadius}}
    />
  );
}

export default LoadingBox;
