import React, {useEffect, useRef} from 'react';
import styles from './NewFormInputText.module.scss';
import NewButton from '../NewButton';

export interface NewFormInputTextProps {
  title?: string;
  description?: string;
  submitButtonText?: string;
  value: string;
  onSubmit?(): void;
  onGoBack?(): void;
  onChange(value: string): void;
  disableText: boolean;
  disableButton: boolean;
  error: {
    trigger: boolean;
    message: string;
  };
}

const NewFormInputText: React.FC<NewFormInputTextProps> = ({
  title,
  description,
  submitButtonText,
  onSubmit,
  onChange,
  onGoBack,
  value,
  disableText,
  disableButton,
  error,
}) => {
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    /* JSDom doesn't support css height changes, so it can't be tested, next line comment excludes it from coverage */
    /* istanbul ignore next */
    if (textAreaRef.current) {
      textAreaRef.current.style.height = `${textAreaRef?.current?.scrollHeight}px`;
    }
  }, [textAreaRef.current?.value]);

  return (
    <form className={styles.wrapperMultipleForm}>
      {title && <h1 className={styles.title}>{title}</h1>}
      {description && (
        <div
          data-testid="description"
          dangerouslySetInnerHTML={{__html: description}}
        />
      )}

      <textarea
        ref={textAreaRef}
        value={value}
        className={styles.input}
        onChange={(event): void => onChange(event.target.value)}
        data-error={error.trigger}
        disabled={disableText}
      />

      {error.trigger && <p className={styles.error}>{error.message}</p>}

      <div className={styles.wrapperSubmit}>
        {onGoBack && (
          <NewButton userRole="student" kind="secondary" onClick={onGoBack}>
            Voltar
          </NewButton>
        )}
        {onSubmit && (
          <NewButton
            userRole="student"
            onClick={onSubmit}
            disabled={disableButton}
          >
            {submitButtonText || 'Enviar'}
          </NewButton>
        )}
      </div>
    </form>
  );
};

export default NewFormInputText;
