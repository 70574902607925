import React, {InputHTMLAttributes, LegacyRef, RefCallback} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import styles from './Search.module.scss';

export interface SearchProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'type' | 'ref'> {
  onClickSearchButton(): void;
  inputRef?: RefCallback<HTMLInputElement> | LegacyRef<HTMLInputElement>;
}

const Search: React.FC<SearchProps> = ({
  onClickSearchButton,
  inputRef,
  ...otherProps
}) => {
  return (
    <div className={styles.searchWrapper}>
      <input
        data-testid="search"
        type="text"
        className={styles.search}
        ref={inputRef}
        {...otherProps}
      />

      <button onClick={onClickSearchButton} className={styles.buttonSearch}>
        <FontAwesomeIcon icon="search" />
      </button>
    </div>
  );
};

export default Search;
