import React from 'react';

import Button from '../LegacyButton';

import styles from './Slider.module.scss';

export type userRole =
  | 'anonymous'
  | 'advanced'
  | 'admin'
  | 'teacher'
  | 'student'
  | 'reviewer';

type SliderProps = {
  title?: string;
  subtitle?: string;
  min: number;
  max: number;
  value: number;
  step?: number;
  userRole?: userRole;
  buttonText?: string;
  onChange: (value: number) => void;
  onSubmit: () => void;
};

const Slider: React.FC<SliderProps> = ({
  title,
  subtitle,
  min,
  max,
  value,
  step,
  userRole = 'anonymous',
  buttonText,
  onChange,
  onSubmit,
}) => {
  function handleChange(value: string): void {
    onChange(Number.parseInt(value, 10));
  }

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>{title}</h1>

      {subtitle && <p className={styles.subtitle}>{subtitle}</p>}

      <div className={styles.inputContainer}>
        <div className={`${styles.value} ${styles[userRole]}`}>{value}</div>

        <input
          className={`${styles.slider} ${styles[userRole]}`}
          type="range"
          min={min}
          max={max}
          value={value}
          readOnly
          step={step}
        />

        <input
          data-testid="thumb"
          className={`${styles.thumb} ${styles[userRole]}`}
          type="range"
          min={min}
          max={max}
          step={step}
          value={value}
          onChange={(event): void => {
            handleChange(event.target.value);
          }}
        />
      </div>

      {buttonText && (
        <Button userRole={userRole} onClick={onSubmit}>
          {buttonText}
        </Button>
      )}
    </div>
  );
};

export default Slider;
