import React from 'react';
import styles from './RoundedButtonGroup.module.scss';

import RoundedButton, {RoundedButtonProps} from '../RoundedButton';

export interface RoundedButtonGroupProps {
  buttonsProps: Array<RoundedButtonProps>;
}

const RoundedButtonGroup: React.FC<RoundedButtonGroupProps> = ({
  buttonsProps,
}) => {
  return (
    <div className={styles.wrapper}>
      {buttonsProps.map((buttonProps: RoundedButtonProps, index: number) => {
        const id = `${index}_${new Date().getTime()}`;
        return <RoundedButton {...buttonProps} key={id} />;
      })}
    </div>
  );
};

export default RoundedButtonGroup;
