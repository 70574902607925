import React from 'react';
import ReactApexChart from 'react-apexcharts';
import {ApexOptions} from 'apexcharts';

import styles from './FinalGradeChart.module.scss';

export interface FinalGradeChartProps {
  size: number;
  competences: Array<{
    name: string;
    score: number;
    maxScore: number;
  }>;

  test?: boolean;
}

const FinalGradeChart: React.FC<FinalGradeChartProps> = ({
  size,
  competences,
  test,
}) => {
  const labels = [];
  const series = [];
  const colors = [];
  let sumValues = 0;
  let sumMaxs = 0;

  // eslint-disable-next-line no-restricted-syntax
  for (const competence of competences) {
    const porcent = competence.score / competence.maxScore;

    sumValues += competence.score;
    sumMaxs += competence.maxScore;

    labels.push(competence.name);
    series.push(competence.score);

    if (porcent < 0.21) {
      colors.push('#FF3B57');
    } else if (porcent < 0.61) {
      colors.push('#FFC105');
    } else {
      colors.push('#0FC78A');
    }
  }

  const humanScore: number = Math.round(sumValues);

  /* istanbul ignore next */
  const options: ApexOptions = {
    colors,
    labels,
    chart: {
      type: 'donut',
      dropShadow: {
        enabled: true,
        top: 1,
        left: 1,
        blur: 2,
        color: '#000',
        opacity: 0.15,
      },
    },
    dataLabels: {
      enabled: true,
      formatter: (_: unknown, {seriesIndex}: {seriesIndex: number}): string =>
        `C.${seriesIndex + 1}`,
      dropShadow: {
        enabled: false,
      },
    },
    legend: {
      show: false,
    },
    responsive: [
      {
        breakpoint: 0,
        options: {
          chart: {
            width: 300,
          },
        },
      },
    ],
    plotOptions: {
      pie: {
        donut: {
          size: '40%',
        },
        expandOnClick: true,
      },
    },
  };

  return series.length > 0 ? (
    <div className={styles.FinalGrade} style={{maxWidth: size + 40}}>
      <div
        className={styles.FinalGradeInfo}
        style={sumValues > 0 ? {alignSelf: 'flex-end'} : {alignSelf: 'center'}}
      >
        <div>
          <div>Nota final</div>
          <div>
            <span className={styles.spotlight}>{humanScore}</span>/{sumMaxs}
          </div>
        </div>
      </div>
      {sumValues > 0 ? (
        <div style={{height: size}}>
          <div className={styles.container} style={{width: size, height: size}}>
            {!test ? (
              /* istanbul ignore next */
              <ReactApexChart options={options} series={series} type="donut" />
            ) : null}
          </div>
          <div className={styles.container} style={{width: size, height: size}}>
            <div
              className={styles.circle}
              style={{
                marginLeft: size * 0.01,
                marginBottom: size * 0.025,
                width: size > 270 ? (size / 2) * (150 / size) : size / 4,
                height: size > 270 ? (size / 2) * (150 / size) : size / 4,
              }}
            >
              {humanScore} pts
            </div>
          </div>
        </div>
      ) : null}
    </div>
  ) : null;
};

export default FinalGradeChart;
