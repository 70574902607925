import React from 'react';
import styles from './Border.module.scss';

export interface BorderProps {
  children: JSX.Element | JSX.Element[];
}

function Border({children}: BorderProps): JSX.Element {
  return <div className={styles.container}>{children}</div>;
}

export default Border;
