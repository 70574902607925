import React from 'react';
import styles from './FormInputText.module.scss';
import Button from '../LegacyButton';

export interface FormMultipleProps {
  title?: string;
  subtitle?: string;
  submitButtonText?: string;
  isButtonDisabled?: boolean;
  value: string;
  onSubmit?(): void;
  onGoBack?(): void;
  onChange(value: string): void;
}

const FormInputText: React.FC<FormMultipleProps> = ({
  title,
  subtitle,
  submitButtonText,
  onSubmit,
  onChange,
  onGoBack,
  isButtonDisabled = false,
  value,
}) => {
  return (
    <div className={styles.wrapperMultipleForm}>
      {title && <h1 className={styles.title}>{title}</h1>}
      {subtitle && <div dangerouslySetInnerHTML={{__html: subtitle}} />}
      <div className={styles.wrapperAnswers} />
      <textarea
        value={value}
        className={styles.input}
        onChange={(event): void => onChange(event.target.value)}
        cols={50}
        rows={10}
      />
      <div className={styles.wrapperSubmit}>
        {onGoBack && (
          <Button userRole="student" kind="secondary" onClick={onGoBack}>
            Voltar
          </Button>
        )}
        {onSubmit && (
          <Button
            userRole="student"
            onClick={onSubmit}
            disabled={isButtonDisabled}
          >
            {submitButtonText}
          </Button>
        )}
      </div>
    </div>
  );
};

export default FormInputText;
