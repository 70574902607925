import React, {InputHTMLAttributes, LegacyRef, RefCallback} from 'react';
import styles from './InputNumber.module.scss';

export interface InputNumberProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'type' | 'ref'> {
  labelText?: string;
  errorMessage?: string;
  inputRef?: RefCallback<HTMLInputElement> | LegacyRef<HTMLInputElement>;
}

const InputNumber: React.FC<InputNumberProps> = ({
  id,
  labelText = '',
  errorMessage = '',
  inputRef,
  ...otherProps
}) => {
  return (
    <>
      {!!labelText && <label htmlFor={id}>{labelText}</label>}

      <input
        id={id}
        type="number"
        className={`${errorMessage ? styles.error : ''}`}
        ref={inputRef}
        {...otherProps}
      />

      {!!errorMessage && (
        <label htmlFor={id} className={styles.errorLabel}>
          {errorMessage}
        </label>
      )}
    </>
  );
};

export default InputNumber;
