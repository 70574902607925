import React from 'react';
import {components} from 'react-select';

// TODO: Tipar corretamente as props quando a lib disponibilizar os types
const NoOptionsMessage: React.FC<any> = props => {
  const c = components as any;
  return (
    <c.NoOptionsMessage {...props}>
      <span className="custom-css-class">Sem opções</span>
    </c.NoOptionsMessage>
  );
};

export default NoOptionsMessage;
