/* eslint-disable react/jsx-no-bind */
import {BasicModal, Button, Tag} from 'components';
import React, {useState} from 'react';
import styles from './FilterModal.module.scss';
import {FiltersList} from '..';

export interface FilterModalProps {
  onClickClose(): void;
  filtersList: FiltersList[];
  selectedFilters?: string[];
  onClickFilterButton(filtersId: string[]): void;
}

function FilterModal({
  onClickClose,
  onClickFilterButton,
  filtersList,
  selectedFilters = [],
}: FilterModalProps): JSX.Element {
  const [filterSelected, setFilterSelected] = useState<string[]>(
    selectedFilters,
  );

  function onClickFilterTag(filterId: string) {
    if (filterSelected.includes(filterId)) {
      setFilterSelected(filterSelected.filter(filter => filter !== filterId));
    } else {
      setFilterSelected([...filterSelected, filterId]);
    }
  }

  function handleClickFilterButton() {
    onClickFilterButton(filterSelected);
    onClickClose();
  }

  return (
    <BasicModal
      open
      onClose={onClickClose}
      clickOutsideToClose
      title="Filtros"
      footerButtons={
        <div className={styles.footerButtons}>
          <Button variant="secondary" text="Voltar" onClick={onClickClose} />
          <Button
            variant="primary"
            text="Filtrar"
            backgroundColor="#3c405f"
            borderColor="#3c405f"
            textColor="#ffffff"
            disabled={
              selectedFilters.length === 0 && filterSelected.length === 0
            }
            onClick={handleClickFilterButton}
          />
        </div>
      }
      footerButtonsOrientation="space-between"
    >
      <section className={styles.container}>
        {filtersList.map(filterList => (
          <div key={filterList?.category} className={styles.card}>
            {filterList?.category && (
              <h4 className={styles.title}>{filterList?.category}</h4>
            )}
            <div className={styles.tagsContainer}>
              {filterList?.filters?.map(filter => (
                <Tag
                  key={filter.id}
                  variant="dismissible"
                  text={filter.text}
                  mainColor="#40357C"
                  onClickTag={() => onClickFilterTag(filter.id)}
                  backgroundColor="#F0EAFD"
                  selected={filterSelected?.includes(filter.id)}
                />
              ))}
            </div>
          </div>
        ))}
      </section>
    </BasicModal>
  );
}

export default FilterModal;
