import React, {useCallback, useEffect, useRef, useState} from 'react';
import NukaCarousel, {CarouselRenderControl} from 'nuka-carousel';
import {SizeProp} from '@fortawesome/fontawesome-svg-core';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import styles from './Carousel.module.scss';
import {UserRole} from '../../../constants';

export type ChildrenHeight = 'first' | 'current' | 'max';

export interface CarouselProps {
  enableKeyBoardsControls?: boolean;
  spaceBetweenSlides?: number;
  childrenHeight?: ChildrenHeight;
  slidesSwiping?: boolean;
  circularQueue?: boolean;
  userRole?: UserRole;
  minWidth: number;
  arrowSize?: SizeProp;
}

const parseNegativeToZero = (value: number): number => {
  return value > 0 ? value : 0;
};

const Carousel: React.FC<CarouselProps> = ({
  children,
  enableKeyBoardsControls = true,
  spaceBetweenSlides = 2,
  childrenHeight = 'max',
  slidesSwiping = true,
  circularQueue = false,
  arrowSize = 'sm',
  minWidth,
}) => {
  const [slidesToShow, setSlidesToShow] = useState(1);
  const slideWidth = minWidth > 0 ? minWidth : 1;
  const carouselRef = useRef<NukaCarousel>(null);

  const renderCenterLeftControls: CarouselRenderControl = ({
    previousSlide,
    currentSlide,
    wrapAround,
  }) => {
    const previousButton = (
      <button
        onClick={previousSlide}
        className={styles.button}
        data-testid="carouselPreviousButton"
      >
        <FontAwesomeIcon icon="arrow-left" size={arrowSize} />
      </button>
    );

    const isFirstSlide = !wrapAround && currentSlide === 0;

    return isFirstSlide ? '' : previousButton;
  };

  const renderCenterRightControls: CarouselRenderControl = ({
    currentSlide,
    slideCount,
    wrapAround,
    slidesToShow,
    nextSlide,
  }) => {
    const nextButton = (
      <button
        onClick={nextSlide}
        className={styles.button}
        data-testid="carouselNextButton"
      >
        <FontAwesomeIcon icon="arrow-right" size={arrowSize} />
      </button>
    );

    const hasNextSlide =
      !wrapAround && currentSlide + slidesToShow >= slideCount;

    return hasNextSlide ? '' : nextButton;
  };

  const handleSlidesToShow = useCallback(
    (frameWidth: number, cellSpacing: number, slideCount: number): void => {
      const spaceBetweenSlides = parseNegativeToZero(
        cellSpacing * slideCount - 1,
      );
      const spaceAvailable = parseNegativeToZero(
        frameWidth - spaceBetweenSlides,
      );
      const maxSlidesToShow = Math.trunc(spaceAvailable / slideWidth);

      if (maxSlidesToShow !== slidesToShow && maxSlidesToShow >= 0) {
        setSlidesToShow(maxSlidesToShow);
      }
    },
    [slideWidth, slidesToShow],
  );

  useEffect(() => {
    if (carouselRef.current) {
      const {cellSpacing} = carouselRef.current.props;
      const {slideCount, frameWidth} = carouselRef.current.state;

      handleSlidesToShow(frameWidth, cellSpacing || 1, slideCount);
    }
  }, [carouselRef.current]);

  return (
    <NukaCarousel
      className={styles.container}
      enableKeyboardControls={enableKeyBoardsControls}
      cellSpacing={spaceBetweenSlides}
      heightMode={childrenHeight}
      slidesToShow={slidesToShow || 1}
      swiping={slidesSwiping}
      wrapAround={circularQueue}
      renderCenterLeftControls={renderCenterLeftControls}
      renderCenterRightControls={renderCenterRightControls}
      renderBottomCenterControls={null}
      ref={carouselRef}
    >
      {React.Children.map(children, (child, index) => {
        const id = `carousel-children-${index}`;

        return (
          <div key={id} className={styles.children}>
            {child}
          </div>
        );
      })}
    </NukaCarousel>
  );
};

export default Carousel;
