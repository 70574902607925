import React, {ReactNode} from 'react';

import styles from './TabItem.module.scss';

interface TabItemProps {
  active: boolean;
  handleClick: React.MouseEventHandler;
  text: ReactNode;
  userRole: string;
  zeroheightStyle?: boolean;
  disabled?: boolean;
}

const TabItem: React.FC<TabItemProps> = ({
  zeroheightStyle = false,
  handleClick,
  text,
  active,
  disabled,
  userRole,
}) => (
  <button
    className={`${styles.tabItemButton} ${active && styles.activeTab} ${
      styles[userRole]
    } ${zeroheightStyle && styles.zeroHeightStyle} ${disabled &&
      styles.disabled}`}
    type="button"
    onClick={handleClick}
    disabled={disabled}
  >
    {text}
  </button>
);

export default TabItem;
