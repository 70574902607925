import React, {HTMLAttributes} from 'react';
import {Border, BorderRadius, Colors, Orientation, Shadow} from 'utils/types';
import styles from './Row.module.scss';

export interface RowProps
  extends Omit<HTMLAttributes<HTMLDivElement>, 'style' | 'className'> {
  orientation?: Orientation;
  border?: Border;
  borderRadius?: BorderRadius;
  borderTopLeftRadius?: BorderRadius;
  borderTopRightRadius?: BorderRadius;
  borderBottomLeftRadius?: BorderRadius;
  borderBottomRightRadius?: BorderRadius;
  background?: Colors;
  shadow?: Shadow;
  // TODO: change to a variable when margins and padding have a standard
  margin?: string;
  padding?: string;
  style?: React.CSSProperties;
}

function Row({
  orientation = 'space-between',
  border,
  borderRadius,
  borderTopLeftRadius,
  borderTopRightRadius,
  borderBottomLeftRadius,
  borderBottomRightRadius,
  background,
  shadow,
  margin,
  padding,
  children,
  style,
  ...otherProps
}: RowProps): JSX.Element {
  return (
    <div
      data-testid="row"
      className={`
        ${styles.rowWrapper}
        ${border ? styles[border] : ''}
        ${background ? styles[background] : ''}
        ${borderRadius ? `${styles.borderRadius} ${styles[borderRadius]}` : ''} 
        ${
          borderTopLeftRadius
            ? `${styles.borderTopLeftRadius} ${styles[borderTopLeftRadius]}`
            : ''
        } 
        ${
          borderTopRightRadius
            ? `${styles.borderTopRightRadius} ${styles[borderTopRightRadius]}`
            : ''
        }
        ${
          borderBottomLeftRadius
            ? `${styles.borderBottomLeftRadius} ${styles[borderBottomLeftRadius]}`
            : ''
        }
        ${
          borderBottomRightRadius
            ? `${styles.borderBottomRightRadius} ${styles[borderBottomRightRadius]}`
            : ''
        }
        ${shadow ? styles[shadow] : ''}
      `}
      style={{
        justifyContent: orientation,
        padding,
        margin,
        ...style,
      }}
      {...otherProps}
    >
      {children}
    </div>
  );
}

export default Row;
