import React from 'react';

import styles from './Steps.module.scss';

export interface StepsProps {
  step: number;
  amount: number;
  questionnaire?: boolean;
}

const Steps: React.FC<StepsProps> = ({step, amount, questionnaire}) => (
  <div className={`${styles.div} ${questionnaire ? styles.questionnaire : ''}`}>
    <label htmlFor="progress" className={styles.label}>
      <p className={styles.subtitle}>
        <span className={styles.step}>{step}</span>/
        <span className={styles.amount}>{amount}</span>
      </p>
    </label>
    <progress id="progress" value={`${step}`} max={`${amount}`} />
  </div>
);

export default Steps;
