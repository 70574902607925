import React, {ReactNode, useRef, useEffect, useState} from 'react';

import RoundedButton, {RoundedButtonProps} from '../RoundedButton';

import styles from './TopBar.module.scss';

interface ButtonStyle {
  minWidth?: number;
  marginRight?: number;
}

export interface TopBarProps {
  title: ReactNode;
  rightButton?: ReactNode;
  centerContent?: ReactNode;
  tabList?: ReactNode;
  buttonList?: Array<RoundedButtonProps>;
  light?: boolean;
}

const MAX_WIDTH_BUTTON_ROUNDED = 82;

const TopBar: React.FC<TopBarProps> = ({
  title,
  rightButton,
  centerContent,
  tabList,
  buttonList,
  light = true,
}) => {
  const buttonsRef = useRef<HTMLDivElement>(null);
  const [buttonStyle, setButtonsStyle] = useState<ButtonStyle>({});

  useEffect(() => {
    if (buttonsRef.current) {
      const maxWidth = [...buttonsRef.current.children]
        .map(elem => elem.clientWidth)
        .reduce((prevWidth, width) => {
          return width > prevWidth ? width : prevWidth;
        }, -1);

      if (
        buttonStyle.minWidth !== maxWidth &&
        maxWidth <= MAX_WIDTH_BUTTON_ROUNDED
      ) {
        setButtonsStyle({
          minWidth: maxWidth,
          marginRight: MAX_WIDTH_BUTTON_ROUNDED - maxWidth,
        });
      }
    }
  }, [buttonList, buttonStyle.minWidth]);

  return (
    <div className={`${styles.container} ${light ? '' : styles.containerDark}`}>
      <div className={styles.leftComponentsWrapper}>
        <div
          className={`
            ${styles.title} 
            ${centerContent ? styles.withCenterContent : ''}
          `}
        >
          {title}
        </div>
        {buttonList && (
          <div ref={buttonsRef} className={styles.buttonRow}>
            {buttonList.map((button, index) => {
              const key = `button${index}`;
              return (
                <div key={key} className={styles.button} style={buttonStyle}>
                  <RoundedButton {...button} />
                </div>
              );
            })}
          </div>
        )}
        {tabList && <div className={styles.tabs}>{tabList}</div>}
      </div>
      {centerContent && (
        <div className={styles.centerContent}>{centerContent}</div>
      )}
      {rightButton && <div className={styles.rightButton}>{rightButton}</div>}
    </div>
  );
};

export default TopBar;
