import React from 'react';
import {components, IndicatorProps} from 'react-select';
import CaretDownIcon from './CaretDownIcon';

const DropdownIndicator = (props: IndicatorProps<any, any>) => {
  const c = components as any;
  return (
    <c.DropdownIndicator {...props}>
      <CaretDownIcon />
    </c.DropdownIndicator>
  );
};

export default DropdownIndicator;
