/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import {Row} from 'components';
import React from 'react';
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from '@fortawesome/react-fontawesome';
import styles from './SelectionCard.module.scss';

export interface SelectionCardProps {
  selected?: boolean;
  icon: FontAwesomeIconProps;
  title?: string;
  subtitle?: string;
  button?: JSX.Element;
  onClickCard?(): void;
}

function SelectionCard({
  selected,
  icon,
  title,
  subtitle,
  button,
  onClickCard,
}: SelectionCardProps): JSX.Element {
  return (
    <article
      className={`
        ${styles.selectionCardWrapper}${' '}
        ${selected && styles.selected}
      `}
      onClick={onClickCard}
      data-testid="selectionCard"
    >
      <div className={`${styles.iconWrapper} ${selected && styles.selected}`}>
        {selected ? (
          <FontAwesomeIcon icon="circle-check" />
        ) : (
          <FontAwesomeIcon icon={['far', 'circle']} />
        )}
      </div>
      <Row orientation="center">
        <FontAwesomeIcon {...icon} />
      </Row>
      {title && <h3>{title}</h3>}
      {subtitle && <h4>{subtitle}</h4>}
      {button && <Row orientation="center">{button}</Row>}
    </article>
  );
}

export default SelectionCard;
