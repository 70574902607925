import React, {ReactNode} from 'react';

import styles from './HighlightCard.module.scss';
import {UserRole} from '../../../constants';

export interface HighlightCardProps {
  children?: ReactNode;
  title: string;
  userRole: UserRole;
  hasMarker?: boolean;
  hasButton?: boolean;
  buttonText?: string;
  onClickButton?: () => void;
  disableButton?: boolean;
  dark?: boolean;
}

const HighlightCard: React.FC<HighlightCardProps> = ({
  children,
  title,
  userRole,
  hasMarker = false,
  hasButton = true,
  onClickButton,
  buttonText = 'Default',
  disableButton = false,
  dark = false,
}) => {
  return (
    <div
      className={`${styles.container} ${styles[userRole]} ${
        dark ? styles.dark : ''
      }`}
    >
      <div className={styles.titleWrapper}>
        <div className={`${styles.leftContent} ${styles[userRole]}`}>
          {hasMarker ? (
            <div data-testid="markerContainer" className={styles.marker} />
          ) : null}
          <p className={styles.title}>{title}</p>
        </div>
        {hasButton ? (
          <>
            <div className={styles.rightContent}>
              <button
                data-testid="highlightButton"
                className={`${styles.button} ${styles[userRole]} ${
                  disableButton ? styles.disabled : styles.roleColor
                }
                ${dark ? styles.dark : ''}`}
                onClick={onClickButton}
                disabled={disableButton}
              >
                {buttonText}
              </button>
            </div>
          </>
        ) : null}
      </div>
      <div className={`${styles.childrenWrapper} ${styles[userRole]}`}>
        {children}
      </div>
    </div>
  );
};

export default HighlightCard;
