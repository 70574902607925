import React, {InputHTMLAttributes, LegacyRef, RefCallback} from 'react';
import styles from './Radio.module.scss';

export interface RadioProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'type'> {
  labelText?: string;
  ref?: RefCallback<HTMLInputElement> | LegacyRef<HTMLInputElement>;
}

const Radio: React.FC<RadioProps> = ({id, labelText, ...otherProps}) => {
  return (
    <div className={styles.inputContainer}>
      <input id={id} type="radio" {...otherProps} />

      {labelText && <label htmlFor={id}>{labelText}</label>}
    </div>
  );
};

export default Radio;
