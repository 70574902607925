import React, {ReactElement, useState, KeyboardEvent} from 'react';
import {SizeProp} from '@fortawesome/fontawesome-svg-core';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import styles from './NewStarRating.module.scss';
import NewButton from '../NewButton';

export interface NewStarRatingProps {
  readonly amountOfStars: number;
  activeStars: number;
  title?: string;
  subtitle?: string;
  onClick(rate: number): void;
  size?: SizeProp;
  smallerRatingLabel?: string;
  biggerRatingLabel?: string;
  submitText?: string;
  isButtonDisabled?: boolean;
  onSubmit?(): void;
  onGoBack?(): void;
  disableHoverEffect?: boolean;
}

export enum StarTypes {
  selected = 'SELECTED',
  hovered = 'HOVERED',
  declined = 'DECLINED',
}

export type StarState = boolean;

type StarsState = Array<StarState>;

const NewStarRating: React.FC<NewStarRatingProps> = ({
  amountOfStars,
  activeStars,
  title,
  subtitle,
  onClick,
  size = '1x',
  submitText,
  smallerRatingLabel,
  biggerRatingLabel,
  onSubmit,
  onGoBack,
  isButtonDisabled = false,
  disableHoverEffect = false,
}) => {
  const [isHovered, setIsHovered] = useState<StarsState>(
    [...Array(amountOfStars).keys()].map(() => false),
  );

  const getStarType = (index: number): StarTypes => {
    if (index < activeStars) return StarTypes.selected;
    if (isHovered[index] && !disableHoverEffect) return StarTypes.hovered;
    return StarTypes.declined;
  };

  const onMouseOver = (hoveredIndex: number): void => {
    const hasStarHovered = hoveredIndex > activeStars - 1;
    if (!hasStarHovered) return;

    const newStarsType = [...Array(amountOfStars).keys()].map(
      (index): StarState => {
        return index <= hoveredIndex;
      },
    );
    setIsHovered(newStarsType);
  };

  const handleStarClick = (index: number): void => {
    onClick(index + 1);
  };

  const onMouseLeave = (): void => {
    const starTypesWithoutHover: StarsState = isHovered.map(
      (): StarState => false,
    );
    setIsHovered(starTypesWithoutHover);
  };
  const renderStars = (): Array<ReactElement> => {
    return [...Array(amountOfStars).keys()].map(
      (index: number): ReactElement => (
        <span
          key={`star-${index}`}
          onKeyPress={(e: KeyboardEvent): void | false => {
            return e.key === 'Enter' && handleStarClick(index);
          }}
          role="button"
          tabIndex={index + 1}
          className={`
            ${styles.star} 
            ${styles[getStarType(index)]} 
            ${disableHoverEffect ? styles.disabledHover : ''}
          `}
          onMouseEnter={(): void => onMouseOver(index)}
          onMouseLeave={onMouseLeave}
          onClick={(): void => handleStarClick(index)}
        >
          <FontAwesomeIcon
            size={size}
            icon={
              getStarType(index) === StarTypes.declined
                ? ['far', 'star']
                : 'star'
            }
          />
        </span>
      ),
    );
  };

  return (
    <div className={styles.wrapper}>
      {title && <h1>{title}</h1>}
      {subtitle && (
        <div
          className={styles.subtitle}
          dangerouslySetInnerHTML={{__html: subtitle}}
        />
      )}
      <div className={styles.starsWrapper}>
        {renderStars()}
        <div className={styles.bottom}>
          {smallerRatingLabel && <span>{smallerRatingLabel}</span>}
          {biggerRatingLabel && <span>{biggerRatingLabel}</span>}
        </div>
      </div>
      <div className={styles.wrapperSubmit}>
        {onGoBack && (
          <NewButton userRole="student" kind="secondary" onClick={onGoBack}>
            Voltar
          </NewButton>
        )}
        {onSubmit && (
          <NewButton
            userRole="student"
            onClick={onSubmit}
            disabled={isButtonDisabled}
          >
            {submitText || 'Enviar'}
          </NewButton>
        )}
      </div>
    </div>
  );
};

export default NewStarRating;
