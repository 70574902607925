import React, {useState} from 'react';
import {IconProp} from '@fortawesome/fontawesome-svg-core';
import ReactDOM from 'react-dom';
import Modal from 'components/infra/Modal';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import styles from './Card.module.scss';
import {UserRole} from '../../../constants';

export type FontColor = 'white' | 'black';

export interface CardProps {
  title?: string;
  icon?: IconProp;
  dark?: boolean;
  userRole?: UserRole;
  modalContent?: React.ReactNode;
  imageSrc?: string;
  fontColor?: FontColor;
  download?(): void;
}

const Card: React.FC<CardProps> = ({
  children,
  title,
  icon,
  dark,
  userRole = 'anonymous',
  modalContent,
  imageSrc,
  fontColor = 'black',
  download,
}) => {
  const [modalOpen, setModalOpen] = useState(false);

  const handleModalChange = () => {
    setModalOpen(!modalOpen);
  };
  const rootElement = document.getElementById('root');

  const backgroundImageCSSProps: React.CSSProperties = {
    backgroundImage: `url(${imageSrc})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  };

  return (
    <>
      <div
        style={imageSrc ? backgroundImageCSSProps : {backgroundImage: ''}}
        className={`
        ${styles.card}
        ${dark ? styles.dark : ''} 
        ${fontColor ? styles[fontColor] : ''}
        ${styles[userRole]}
      `}
      >
        <div className={styles.topButtons}>
          {download && (
            <button
              type="button"
              onClick={download}
              data-testid="downloadButton"
              aria-label="baixar atividade"
              className={styles.downloadButton}
            >
              <FontAwesomeIcon icon={['fad', 'download']} />
            </button>
          )}
          <button
            className={styles.infoButton}
            title="descrição detalhada"
            onClick={handleModalChange}
            disabled={!modalContent}
          >
            i
          </button>
        </div>

        {title && (
          <header title={title} className={styles.title}>
            {icon && <FontAwesomeIcon icon={icon} />}
            <h2>{title}</h2>
          </header>
        )}

        <div className={styles.body}>{children}</div>
      </div>
      {rootElement &&
        !!modalContent &&
        ReactDOM.createPortal(
          <Modal
            isOpen={modalOpen}
            onClose={handleModalChange}
            clickOutsideToClose
          >
            {modalContent}
          </Modal>,
          rootElement,
        )}
    </>
  );
};

export default Card;
