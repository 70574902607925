import React, {ReactNode} from 'react';

import styles from './Header.module.scss';

export interface HeaderProps {
  userRole?: string;
  leftComponent: string | ReactNode;
  rightComponent?: string | ReactNode;
}

const Header: React.FC<HeaderProps> = ({
  userRole = 'student',
  leftComponent,
  rightComponent = '',
}) => (
  <header
    data-testid="header"
    className={`${styles.Header} ${styles[`${userRole}`]}`}
  >
    <div>{leftComponent}</div>

    <div>{rightComponent}</div>
  </header>
);

export default Header;
