import React, {useState} from 'react';
import {Tag} from 'components';
import styles from './Filter.module.scss';
import FilterModal from './FilterModal';

export interface FiltersList {
  category?: string;
  filters: {
    text: string;
    id: string;
  }[];
}

export interface FilterProps {
  mainFilters?: {
    text: string;
    id: string;
  }[];
  selectedFilters?: string[];
  allFilters: FiltersList[];
  onClickMainFilter?(filterId: string): void;
  onClickFilterButton(filtersId: string[]): void;
}

function Filter({
  mainFilters,
  selectedFilters,
  allFilters,
  onClickMainFilter,
  onClickFilterButton,
}: FilterProps): JSX.Element {
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);

  return (
    <div className={styles.wrapper}>
      <Tag
        variant="operational"
        text="Filtros"
        leftIcon={{icon: 'sliders'}}
        mainColor="#40357C"
        onClickTag={() => setIsFilterModalOpen(true)}
        backgroundColor="#F0EAFD"
      />

      {mainFilters?.map(filter => (
        <Tag
          key={filter.id}
          variant="dismissible"
          text={filter.text}
          onClickTag={() => onClickMainFilter && onClickMainFilter(filter.id)}
          mainColor="#40357C"
          backgroundColor="#F0EAFD"
          selected={selectedFilters?.includes(filter.id)}
        />
      ))}

      {isFilterModalOpen && (
        <FilterModal
          onClickClose={() => setIsFilterModalOpen(false)}
          filtersList={allFilters}
          selectedFilters={selectedFilters}
          onClickFilterButton={onClickFilterButton}
        />
      )}
    </div>
  );
}

export default Filter;
