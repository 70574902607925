import React, {ReactNode, useState, useEffect, MouseEventHandler} from 'react';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import RoundedButtonGroup from '../RoundedButtonGroup';
import RoundedButton, {RoundedButtonProps} from '../RoundedButton';

import styles from './ContentWrapper.module.scss';
import {UserRole} from '../../../constants/index';

export type Status = 'closed' | 'open' | 'full';

export type ContentWrapperProps = {
  status: Status;
  bodyContent: Element | HTMLHtmlElement | ReactNode | React.FC;
  sidebarContent: Element | HTMLHtmlElement | ReactNode | React.FC;
  buttonsProps?: Array<RoundedButtonProps>;
  onExitClick?: React.MouseEventHandler;
  userRole?: UserRole;
  hasOpenCloseSidebarButton?: boolean;
};

const ContentWrapper: React.FC<ContentWrapperProps> = ({
  status,
  bodyContent,
  sidebarContent,
  buttonsProps,
  onExitClick,
  userRole = 'student',
  hasOpenCloseSidebarButton,
}) => {
  const [sidebarStatus, setSidebarStatus] = useState<Status>(status);

  useEffect(() => {
    setSidebarStatus(status);
  }, [status]);

  const handleClickExit: MouseEventHandler = event => {
    if (onExitClick) {
      onExitClick(event);
    }

    setSidebarStatus('closed');
  };

  const hanldeClickToggleSideBarStatus = (): void => {
    setSidebarStatus(sidebarStatus === 'closed' ? 'open' : 'closed');
  };

  return (
    <div className={styles.container}>
      <div
        data-testid="bodyContent"
        className={`
        ${styles.bodyContent} 
        ${styles[sidebarStatus]}
        ${buttonsProps?.length ? styles.withVerticalButtons : ''}
        ${hasOpenCloseSidebarButton ? styles.withOpenSidebarButton : ''}
      `}
      >
        {bodyContent}
      </div>
      <div
        data-testid="sidebar"
        className={`
          ${styles.sidebar} 
          ${styles[sidebarStatus]}
          ${buttonsProps?.length ? styles.withVerticalButtons : ''}
          ${hasOpenCloseSidebarButton ? styles.withOpenSidebarButton : ''}
        `}
      >
        <div
          data-testid="verticalButtons"
          className={`${styles.verticalButtons}`}
        >
          {buttonsProps?.length && (
            <RoundedButtonGroup buttonsProps={buttonsProps} />
          )}
        </div>
        <div className={`${styles.exitButton} ${styles[sidebarStatus]}`}>
          <button
            className={styles.button}
            onClick={handleClickExit}
            data-testid="exitButton"
          >
            <FontAwesomeIcon icon={['fal', 'times']} />
          </button>
        </div>
        {hasOpenCloseSidebarButton ? (
          <div
            className={`${styles.openSidebarButton}`}
            data-testid="openSidebarButton"
          >
            <RoundedButton
              onClick={hanldeClickToggleSideBarStatus}
              icon={{
                icon:
                  sidebarStatus === 'closed'
                    ? 'chevron-double-left'
                    : 'chevron-double-right',
              }}
              userRole={userRole}
            />
          </div>
        ) : (
          ''
        )}
        <div className={styles.sidebarContent}>{sidebarContent}</div>
      </div>
    </div>
  );
};

export default ContentWrapper;
