import React, {ChangeEvent} from 'react';
import styles from './TableHeader.module.scss';

export interface TableHeaderProps {
  headings: string[];
  className?: string;
  onCheckAll?(checked: boolean): void;
}

function TableHeader({
  headings,
  className,
  onCheckAll,
}: TableHeaderProps): JSX.Element {
  function handleCheckboxChange(event: ChangeEvent<HTMLInputElement>) {
    const {checked} = event.target;
    const tableBody = event.target.closest('table')?.querySelector('tbody');

    onCheckAll?.(checked);
    updateAllTbodyCheckbox(checked, tableBody);
  }

  function updateAllTbodyCheckbox(
    status: boolean,
    tableBody?: HTMLTableSectionElement | null,
  ) {
    const tableBodyChildArray = Array.from(tableBody?.children ?? []);

    tableBodyChildArray.forEach(child => {
      const childInput = child.querySelector('input');

      if (childInput?.type === 'checkbox') {
        childInput.checked = status;
      }
    });
  }

  return (
    <thead className={`${styles.header} ${className}`}>
      <tr>
        {onCheckAll && (
          <th className={styles.checkbox}>
            <input
              type="checkbox"
              data-testid="check-all"
              onChange={handleCheckboxChange}
              aria-label="selecionar todos"
            />
          </th>
        )}

        {headings.map(heading => (
          <th key={heading}>{heading}</th>
        ))}
      </tr>
    </thead>
  );
}

export default TableHeader;
