import React from 'react';
import styles from './FormMultiple.module.scss';
import Button from '../LegacyButton';

type ItemId = number | string;

interface Item {
  text: string;
  id: ItemId;
}

export interface FormMultipleProps {
  items: Array<Item>;
  onClickItem(itemId: ItemId): void;
  activeItems: Array<ItemId>;
  title: string;
  subtitle?: string;
  submitButtonText?: string;
  isButtonDisabled?: boolean;
  onSubmit?(): void;
  onGoBack?(): void;
}

const FormMultiple: React.FC<FormMultipleProps> = ({
  items,
  onClickItem,
  activeItems,
  title,
  subtitle,
  submitButtonText,
  onSubmit,
  onGoBack,
  isButtonDisabled = false,
}) => {
  const isButtonActive = (item: Item): boolean =>
    activeItems.some((activeItem: any) => activeItem === item.id);
  return (
    <div className={styles.wrapperMultipleForm}>
      <h1 className={styles.title}>{title}</h1>
      {subtitle && <div dangerouslySetInnerHTML={{__html: subtitle}} />}
      <div className={styles.wrapperAnswers}>
        {items.map((item: Item) => (
          <button
            key={item.id}
            className={`${styles.button} ${
              isButtonActive(item) ? styles.active : ''
            }`}
            onClick={(): void => {
              onClickItem(item.id);
            }}
          >
            {item.text}
          </button>
        ))}
      </div>
      <div className={styles.wrapperSubmit}>
        {onGoBack && (
          <Button kind="secondary" userRole="student" onClick={onGoBack}>
            Voltar
          </Button>
        )}
        {onSubmit && (
          <Button
            userRole="student"
            onClick={onSubmit}
            disabled={isButtonDisabled}
          >
            {submitButtonText}
          </Button>
        )}
      </div>
    </div>
  );
};

export default FormMultiple;
