import React, {ReactNode, useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import styles from './Accordeon.module.scss';

export interface Item {
  titleLeft?: ReactNode;
  titleRight?: ReactNode;
  content: ReactNode;
}
export interface AccordeonProps {
  items: Array<Item>;
  onItemClick?(itemIndex?: number): void;
}

const Accordeon: React.FC<AccordeonProps> = ({items, onItemClick}) => {
  const [selectedItemIndex, setSelectedItemIndex] = useState<number | null>(
    null,
  );

  const handleItemClick = (itemIndex: number): void => {
    if (selectedItemIndex === itemIndex) {
      setSelectedItemIndex(null);
    } else {
      setSelectedItemIndex(itemIndex);
      onItemClick?.(itemIndex);
    }
  };

  return (
    <ul className={styles.list}>
      {items.map((item, index) => {
        const isOpen = index === selectedItemIndex;
        const key = `item_${index}`;

        return (
          <li
            className={`${styles.wrapper} ${isOpen ? styles.open : ''}`}
            key={key}
          >
            <button
              className={`${styles.title} ${isOpen ? styles.open : ''}`}
              onClick={() => handleItemClick(index)}
            >
              <span className={styles.titleContent} tabIndex={-1}>
                <div className={styles.left}>{item.titleLeft}</div>
                <div className={styles.right}>
                  <div className={item.titleRight ? styles.hasTitle : ''}>
                    {item.titleRight}
                  </div>
                  <FontAwesomeIcon icon="chevron-down" />
                </div>
              </span>
            </button>
            <div className={styles.content}>{item.content}</div>
          </li>
        );
      })}
    </ul>
  );
};

export default Accordeon;
