import React from 'react';
import ReactSelect, {Props} from 'react-select';
import DropdownIndicator from './DropdownIndicator';
import NoOptionsMessage from './NoOptionsMessage';
import styles from './Select.module.scss';

export interface SelectProps extends Props {
  label?: string;
  errorMessage?: string;
}

const Select: React.FC<SelectProps> = ({
  label = '',
  id,
  placeholder = '',
  errorMessage = '',
  ...otherProps
}) => {
  return (
    <div className={styles.container}>
      {label ? <p>{label}</p> : null}

      <ReactSelect
        id={id}
        components={{DropdownIndicator, NoOptionsMessage}}
        placeholder={placeholder || 'Selecionar...'}
        styles={{
          placeholder: provided => ({
            ...provided,
            fontSize: '16px',
            color: '#95989A',
          }),
          control: provided => ({
            ...provided,
            borderColor: errorMessage ? '#f24e54' : '#95989A',
            '&:hover': {borderColor: '#5d3d85'},
          }),
          dropdownIndicator: provided => ({
            ...provided,
            color: '#95989A',
            paddingRight: 12,
          }),
          indicatorSeparator: () => ({}),
        }}
        {...otherProps}
      />

      {errorMessage && <p className={styles.errorLabel}>{errorMessage}</p>}
    </div>
  );
};

export default Select;
