import React, {ReactNode} from 'react';
import {IconProp} from '@fortawesome/fontawesome-svg-core';
import NewButton from 'components/infra/NewButton';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import styles from './PageHeaderContent.module.scss';
import {UserRole} from '../../../constants';

export interface DataObject {
  icon?: IconProp;
  iconColor?: string;
  title?: string | ReactNode;
  content?: string | ReactNode;
}

export type PageHeaderProps = {
  pageContent?: DataObject;
  pageTitle: string;
  pageSubtitle?: string;
  pageGenre?: string;
  divBorder?: boolean;
  onClickBack?: () => void;
  onClickButton?: () => void;
  backButton?: boolean;
  button?: boolean;
  buttonText?: string;
  buttonUserRole?: UserRole;
  borderBottom?: boolean;
  onClickSecondButton?: () => void;
  hasSecondButton?: boolean;
  secondButtonText?: string;
};

const PageHeaderContent: React.FC<PageHeaderProps> = ({
  pageContent,
  pageTitle,
  onClickBack,
  onClickButton,
  backButton,
  pageGenre,
  pageSubtitle,
  buttonText,
  button,
  divBorder = true,
  buttonUserRole = 'anonymous',
  borderBottom = true,
  onClickSecondButton,
  hasSecondButton = false,
  secondButtonText,
}) => {
  return (
    <div
      data-testid="pageHeaderContent"
      className={`${pageContent && styles.withReactContent} ${
        divBorder ? '' : styles.onlyTitle
      }`}
    >
      <div
        className={`${styles.pageTitle} ${borderBottom ? styles.border : ''}`}
      >
        <div>
          <div
            className={`${
              pageGenre && pageSubtitle ? styles.titleWithContent : styles.title
            }`}
          >
            {backButton && (
              <button
                data-testid="backButton"
                onClick={onClickBack}
                className={styles.backButton}
              >
                <FontAwesomeIcon icon="chevron-left" />
              </button>
            )}
            {pageTitle}
          </div>
          {pageSubtitle && (
            <div className={styles.pageSubtitle}>{pageSubtitle}</div>
          )}
          {pageGenre && <div className={styles.pageGenre}>{pageGenre}</div>}
        </div>
        <div
          className={`${
            pageGenre && pageSubtitle ? styles.button : styles.onlyButton
          }`}
        >
          {button && (
            <NewButton
              kind="primary"
              onClick={onClickButton}
              size="small"
              text={buttonText}
              type="button"
              userRole={buttonUserRole}
            />
          )}
          {hasSecondButton && (
            <NewButton
              kind="primary"
              onClick={onClickSecondButton}
              size="small"
              text={secondButtonText}
              type="button"
              userRole="student"
            />
          )}
        </div>
      </div>
      {pageContent && (
        <div className={styles.specs}>
          <div className={styles.specsContent}>
            {pageContent.icon && (
              <FontAwesomeIcon
                icon={pageContent.icon}
                color={pageContent.iconColor}
              />
            )}
            <span>
              {pageContent.title} {pageContent.content}
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

export default PageHeaderContent;
