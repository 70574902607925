import React, {InputHTMLAttributes, LegacyRef} from 'react';
import styles from './TextArea.module.scss';

export interface TextAreaProps
  extends InputHTMLAttributes<HTMLTextAreaElement> {
  labelText?: string;
  errorMessage?: string;
  ref?: LegacyRef<HTMLTextAreaElement>;
}

const TextArea: React.FC<TextAreaProps> = ({
  id,
  labelText = '',
  errorMessage = '',
  ...otherProps
}) => {
  return (
    <>
      {labelText && <label htmlFor={id}>{labelText}</label>}

      <textarea
        id={id}
        rows={4}
        className={`${errorMessage ? styles.error : ''}`}
        {...otherProps}
      />

      {errorMessage && (
        <label htmlFor={id} className={styles.errorLabel}>
          {errorMessage}
        </label>
      )}
    </>
  );
};

export default TextArea;
