import React from 'react';
import {IconProp} from '@fortawesome/fontawesome-svg-core';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import styles from './Title.module.scss';

export type TitleProps = {
  text: string;
  icon?: IconProp;
  onClick: () => void;
  noWrap?: boolean;
};

const Title: React.FC<TitleProps> = ({icon, text, onClick, noWrap = false}) => (
  <div>
    <h1
      className={`
        ${styles.text} 
        ${noWrap ? styles.noWrap : ''}
    `}
    >
      {icon ? (
        <span
          className={styles.icon}
          onClick={onClick}
          onKeyPress={onClick}
          role="button"
          tabIndex={0}
        >
          <FontAwesomeIcon icon={icon} />
        </span>
      ) : null}
      {text}
    </h1>
  </div>
);

export default Title;
