import React, {useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import styles from './Alert.module.scss';

export type Type = 'error' | 'success' | 'warning';

enum iconType {
  success = 'check-circle',
  error = 'times-circle',
  warning = 'exclamation-circle',
}

export interface AlertProps {
  text?: string;
  onClose?: () => void;
  type?: Type;
  time?: number;
}
const Alert: React.FC<AlertProps> = ({
  text,
  onClose,
  type = 'error',
  time = 5000,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(true);

  setTimeout(() => {
    setIsOpen(false);
  }, time);

  return (
    <>
      {isOpen ? (
        <div className={styles.alertContainer}>
          <div
            data-testid="alert"
            className={`${styles.alert} ${styles[type]} ${styles.visible}`}
          >
            <div className={styles.alertText}>
              <div className={styles[type]}>
                <FontAwesomeIcon icon={iconType[type]} />
              </div>
              <div className={styles.alertTextContent}>{text}</div>
            </div>
            <button
              className={styles.closeIcon}
              onClick={onClose}
              data-testid="closeAlert"
            >
              <FontAwesomeIcon icon={['fal', 'times']} />
            </button>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default Alert;
