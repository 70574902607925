import React from 'react';
import {IconProp} from '@fortawesome/fontawesome-svg-core';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import styles from './Stepper.module.scss';
import {UserRole} from '../../../constants';

export interface Step {
  type: 'questionnaire' | 'composition' | 'content' | '';
  id: string;
  clickable: boolean;
}

export interface StepperProps {
  steps: Array<Step>;
  activeStepNumber?: number;
  userRole?: UserRole;
  finished?: boolean;
  onClickStep?: (step: Step) => void;
}

type StepStatusClass = 'completed' | 'active' | '';
type StepPositionClass = 'first' | 'middle' | 'last';

const Stepper: React.FC<StepperProps> = ({
  steps,
  activeStepNumber,
  userRole = 'anonymous',
  finished = false,
  onClickStep,
}) => {
  const widthPercentage = 100 / steps.length;

  const getStepStatusClassName = (stepNumber: number): StepStatusClass => {
    if (activeStepNumber === undefined || stepNumber > activeStepNumber) {
      return '';
    }

    return finished || stepNumber < activeStepNumber ? 'completed' : 'active';
  };

  const getStepPositionClassName = (stepNumber: number): StepPositionClass => {
    if (stepNumber > 1 && stepNumber < steps.length) {
      return 'middle';
    }

    return stepNumber === 1 ? 'first' : 'last';
  };

  const handleClickStep = (step: Step): void => {
    if (!step.clickable) {
      return;
    }

    const stepIndex = steps.findIndex(
      stepCompare => stepCompare.id === step.id,
    );
    const stepNumber = stepIndex + 1;

    if (
      onClickStep &&
      activeStepNumber !== undefined &&
      stepNumber <= activeStepNumber
    ) {
      onClickStep(step);
    }
  };

  const getStepIconName = (step: Step): IconProp => {
    switch (step.type) {
      case 'questionnaire':
        return 'check';
      case 'composition':
        return 'pencil-alt';
      case 'content':
        return 'book-open';
      default:
        return 'exclamation-circle';
    }
  };

  const getStepTitle = (step: Step): string => {
    switch (step.type) {
      case 'questionnaire':
        return 'Questionário';
      case 'composition':
        return 'Escrita';
      case 'content':
        return 'Conteúdo pedagógico';
      default:
        return '';
    }
  };

  return (
    <div className={styles.container}>
      {steps.map((step, stepIndex) => {
        const stepNumber = stepIndex + 1;

        return (
          <div
            className={styles.step}
            key={step.id}
            style={{width: `${widthPercentage}%`}}
          >
            <div
              className={`
                ${styles.thumb} 
                ${styles[getStepStatusClassName(stepNumber)]} 
                ${styles[userRole]}
                ${!step.clickable ? styles.notClickable : ''}
              `}
              onClick={(): void => handleClickStep(step)}
              onKeyPress={(): void => handleClickStep(step)}
              role="button"
              tabIndex={0}
              title={getStepTitle(step)}
              data-testid={stepNumber}
            >
              <FontAwesomeIcon icon={getStepIconName(step)} size="xs" />
            </div>
            <div
              className={`
                ${styles.track} 
                ${styles[getStepPositionClassName(stepNumber)]} 
                ${styles[userRole]}
              `}
            />
          </div>
        );
      })}
    </div>
  );
};

export default Stepper;
