import React, {ReactNode} from 'react';
import styles from './SectionWrapper.module.scss';

export type SectionWrapperProps = {
  children?: ReactNode;
};

const SectionWrapper: React.FC<SectionWrapperProps> = ({children}) => {
  return (
    <div data-testid="sectionWrapper" className={styles.sectionWrapper}>
      {children}
    </div>
  );
};

export default SectionWrapper;
