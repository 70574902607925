import React from 'react';
import styles from './DevolutiveCard.module.scss';

export interface DevolutiveCardProps {
  showShadows?: boolean;
  children: React.ReactNode;
}

const DevolutiveCard: React.FC<DevolutiveCardProps> = ({
  showShadows,
  children,
}) => (
  <div
    className={`${styles.container} ${showShadows ? styles.showShadows : ''}`}
  >
    {children}
  </div>
);

export default DevolutiveCard;
