import React, {ReactNode} from 'react';
import styles from './TabsContainer.module.scss';

interface TabsData {
  title: string;
  id: number;
  children: ReactNode;
}

export interface TabListProps {
  tabs: Array<TabsData>;
  handleTabChange: (id: number) => void;
  tabActive?: number;
}

const TabList: React.FC<TabListProps> = ({
  tabs,
  handleTabChange,
  tabActive = 0,
}) => {
  const onTabChange = (id: number): void => {
    handleTabChange(id);
  };

  return (
    <div className={styles.tabsContainer}>
      <div className={styles.tabsWrapper}>
        {tabs.map(tab => (
          <button
            className={`${styles.tab} ${
              tabActive === tab.id ? styles.tabActive : ''
            }`}
            key={tab.id}
            onClick={() => onTabChange(tab.id)}
          >
            {tab.title}
          </button>
        ))}
      </div>
      <div className={styles.activeTabContent}>{tabs[tabActive].children}</div>
    </div>
  );
};

export default TabList;
