/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-nested-ternary */
import NewButton, {NewButtonProps} from 'components/infra/NewButton';
import Pagination from 'components/infra/Pagination';
/* eslint-disable react/jsx-key */
import React, {ReactNode, useEffect} from 'react';
import {TableInstance} from 'react-table';
import {v4 as uniqueKeyGenerator} from 'uuid';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import styles from './LegacyTable.module.scss';

interface TableData extends Partial<TableInstance> {
  state: any;
  page?: any;
  headerGroups?: any;
  getTableBodyProps: any;
  getTableProps: any;
  prepareRow: any;
}
export interface TableProps {
  tableData: TableData;
  title?: string | ReactNode;
  buttonProps?: NewButtonProps;
  onClickButton?: () => void;
  isLoading: boolean;
  divBorder?: boolean;
  dataCount: number;
  onClickHoverButton?: () => void;
  children?: ReactNode;
  currentPage?: number;
  setCurrentPage?: (page: number) => void;
  onSort?(data: any): any;
  onClickRow?: any;
}

const LegacyTable: React.FC<TableProps> = ({
  children,
  tableData,
  title,
  onClickRow,
  buttonProps = {
    icon: 'alarm-clock',
    kind: 'primary',
    userRole: 'teacher',
    type: 'button',
    text: 'Default Button',
    disabled: false,
    isLoading: false,
    size: 'small',
  },
  onClickHoverButton,
  onClickButton,
  divBorder,
  isLoading,
  dataCount,
  currentPage,
  setCurrentPage,
  onSort,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    state: {pageSize, sortBy},
  } = tableData;
  const pageCount = dataCount ? Math.ceil(dataCount / pageSize) : 0;
  const allowNextPage = currentPage
    ? dataCount > pageSize * currentPage
    : false;
  const allowPreviousPage = currentPage ? currentPage > 1 : false;

  useEffect(() => {
    if (onSort) onSort(sortBy);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortBy]);

  return (
    <div className={`${styles.wrapper} ${divBorder ? styles.border : ''}`}>
      <div
        className={`${styles.tableHeader} ${
          title || onClickButton ? styles.borderHeader : ''
        }`}
      >
        {title && <div>{title}</div>}
        <div />
        {onClickButton && (buttonProps.icon || buttonProps.text) && (
          <NewButton
            onClick={onClickButton}
            icon={buttonProps.icon}
            text={buttonProps.text}
            kind={buttonProps.kind}
            type={buttonProps.type}
            isLoading={buttonProps.isLoading}
            disabled={buttonProps.disabled}
            size={buttonProps.size}
            userRole={buttonProps.userRole}
          />
        )}
      </div>
      {children && <div>{children}</div>}
      <table
        {...getTableProps()}
        className={`${styles.table} ${isLoading ? styles.loading : ''}`}
      >
        <thead>
          {headerGroups.map((headerGroup: any) => (
            <tr
              {...{
                ...headerGroup.getHeaderGroupProps(),
                key: uniqueKeyGenerator(),
              }}
            >
              {headerGroup.headers.map((column: any) => (
                <th
                  {...{
                    ...column.getHeaderProps(column.getSortByToggleProps()),
                    key: uniqueKeyGenerator(),
                  }}
                  className={styles.header}
                >
                  {column.render('Header')}
                  {column.isSorted ? (
                    column.isSortedDesc ? (
                      <FontAwesomeIcon
                        icon={['fas', 'sort-down']}
                        color="#414449"
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={['fas', 'sort-up']}
                        color="#414449"
                      />
                    )
                  ) : (
                    ''
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row: any) => {
            prepareRow(row);
            return (
              <tr
                {...{...row.getRowProps(), key: uniqueKeyGenerator()}}
                className={onClickRow ? styles.rowClickable : ''}
                onClick={
                  onClickRow ? (): void => onClickRow(row.original) : null
                }
                data-testid="tableRow"
              >
                {row.cells.map((cell: any) => {
                  if (
                    onClickHoverButton &&
                    cell.column.Header === 'Ação' &&
                    cell.render('Cell') !== ''
                  ) {
                    return (
                      <td
                        key={uniqueKeyGenerator()}
                        className={`${styles.cell} ${styles.cellHover}`}
                      >
                        <div data-clickable className={styles.hoverWrapper}>
                          <button
                            onClick={onClickHoverButton}
                            className={styles.hoverButton}
                            type="button"
                          >
                            {cell.render('Cell')}
                          </button>
                        </div>
                      </td>
                    );
                  }
                  return (
                    <td
                      {...{...cell.getCellProps(), key: uniqueKeyGenerator()}}
                      className={styles.cell}
                    >
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>

      {currentPage && setCurrentPage && (
        <div className={styles.pagination}>
          {pageCount === 0 ? null : (
            <Pagination
              currentPage={currentPage}
              totalPages={pageCount}
              hasNext={allowNextPage}
              hasPrevious={allowPreviousPage}
              onChange={setCurrentPage}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default LegacyTable;
