import React from 'react';

export interface ImageProps {
  imageSrc: string | any;
  alt?: string;
}

const Image: React.FC<ImageProps> = ({imageSrc, alt = ''}) => (
  <img src={imageSrc} alt={alt} />
);

export default Image;
