import React, {useState} from 'react';

import styles from './NewImage.module.scss';

export interface NewImageProps {
  imageSrc: string | any;
  alt?: string;
  containerHeight?: number | string;
  containerWidht?: number | string;
}

const NewImage: React.FC<NewImageProps> = ({
  imageSrc,
  alt = '',
  containerHeight,
  containerWidht,
}) => {
  const [isImageOpen, setIsImageOpen] = useState<boolean>(false);

  const toggleImageOpen = () => {
    setIsImageOpen(_isImageOpen => !_isImageOpen);
  };

  return (
    <div
      style={{width: containerWidht, height: containerHeight}}
      className={`${styles.image} ${isImageOpen ? styles.imageHover : ''}`}
    >
      <img
        data-testid="newImage"
        onKeyPress={toggleImageOpen}
        role="none"
        onClick={toggleImageOpen}
        src={imageSrc}
        alt={alt}
      />
    </div>
  );
};

export default NewImage;
