/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-no-bind */
import React, {ForwardedRef, InputHTMLAttributes, forwardRef} from 'react';
import Datetime from 'react-datetime';
import {Moment} from 'moment';
import styles from './TimeInput.module.scss';
import './react-datetime.scss';

export interface TimeInputProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  handleChange(value: string | Moment): void;
}

/**
 * Selects a time using timepicker.
 *
 * This component passes a ref to the input element
 * so it can be used with react-hook-form or other form libraries.
 *
 * @param {string} label - An label for input
 * @return value - an string | Moment with the selected time
 */
function TimeInput(
  {label, handleChange, ...inputProps}: TimeInputProps,
  ref: ForwardedRef<HTMLInputElement>,
): JSX.Element {
  return (
    <div className={styles.timeInputContainer}>
      <label htmlFor={inputProps.id} className={styles.label}>
        {label}
      </label>
      <Datetime
        closeOnSelect
        strictParsing
        locale="pt-br"
        timeFormat="HH:mm"
        onChange={handleChange}
        dateFormat={false}
        className={styles.pickerInput}
        inputProps={{
          placeholder: '-- : --',
          className: styles.timeInput,
          readOnly: true,
          ref,
          ...inputProps,
        }}
        closeOnClickOutside
      />
    </div>
  );
}

export default forwardRef(TimeInput);
