import React, {ReactNode} from 'react';
import {IconProp} from '@fortawesome/fontawesome-svg-core';
import SectionWrapper from 'components/infra/SectionWrapper';
import PageHeaderContent from 'components/infra/PageHeaderContent';
import {UserRole} from '../../../constants';

export interface DataObject {
  icon?: IconProp;
  iconColor?: string;
  title?: string | ReactNode;
  content?: string | ReactNode;
}

export type PageHeaderProps = {
  pageContent?: DataObject;
  pageTitle: string;
  pageSubtitle?: string;
  pageGenre?: string;
  divBorder?: boolean;
  onClickBack?: () => void;
  onClickButton?: () => void;
  backButton?: boolean;
  button?: boolean;
  buttonText?: string;
  buttonUserRole?: UserRole;
  borderBottom?: boolean;
  onClickSecondButton?: () => void;
  hasSecondButton?: boolean;
  secondButtonText?: string;
};

const PageHeader: React.FC<PageHeaderProps> = ({
  pageContent,
  pageTitle,
  onClickBack,
  onClickButton,
  backButton,
  pageGenre,
  pageSubtitle,
  buttonText,
  button,
  divBorder = true,
  buttonUserRole = 'anonymous',
  borderBottom = true,
  onClickSecondButton,
  hasSecondButton = false,
  secondButtonText,
}) => {
  return (
    <SectionWrapper>
      <PageHeaderContent
        pageContent={pageContent}
        pageTitle={pageTitle}
        onClickBack={onClickBack}
        onClickButton={onClickButton}
        backButton={backButton}
        pageGenre={pageGenre}
        pageSubtitle={pageSubtitle}
        buttonText={buttonText}
        button={button}
        divBorder={divBorder}
        buttonUserRole={buttonUserRole}
        borderBottom={borderBottom}
        hasSecondButton={hasSecondButton}
        onClickSecondButton={onClickSecondButton}
        secondButtonText={secondButtonText}
      />
    </SectionWrapper>
  );
};

export default PageHeader;
