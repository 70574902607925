import React, {ReactNode, useState} from 'react';

import styles from './ChoiceChips.module.scss';

export type Alignment = 'horizontal' | 'vertical';
export type Size = 'regular' | 'large' | 'default';
export type Competence = 'c1' | 'c2' | 'c3' | 'c4' | 'c5';

export type ItemsObject = {
  id: number;
  content: string | ReactNode;
  competence?: Competence | string;
  value: string | number;
  disabled?: boolean;
  color?: string;
};

export interface ChoiceChipsProps {
  competences?: boolean;
  alignment?: Alignment;
  items: Array<ItemsObject>;
  selected?: string | number;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  size?: Size;
}

const ChoiceChips: React.FC<ChoiceChipsProps> = ({
  alignment,
  items,
  onChange,
  selected,
  size = 'default',
  competences = false,
}) => {
  const [hover, setHover] = useState<string | undefined>(undefined);

  return (
    <div
      className={`${styles.choicesWrapper} ${alignment && styles[alignment]}`}
    >
      {items.map(({id, content, value, disabled, competence, color}) => (
        <React.Fragment key={id}>
          <input
            type="radio"
            className={styles.choiceInput}
            onChange={onChange}
            id={`choice-${id}`}
            data-testid={`getChoiceId_${id}`}
            disabled={disabled}
            checked={value === selected}
            value={value}
          />
          <label
            htmlFor={`choice-${id}`}
            className={`${styles.choiceLabel} ${competences &&
              `${styles.competences}
              ${competence && styles[competence]}`} ${size && styles[size]} ${
              disabled ? styles.disabled : ''
            }`}
            onMouseEnter={(): void => setHover(competence)}
            onMouseLeave={(): void => setHover(undefined)}
            data-testid={`choiceLabel_${id}`}
            style={
              color && (hover === competence || value === selected)
                ? {backgroundColor: color}
                : {}
            }
          >
            {content}
          </label>
        </React.Fragment>
      ))}
    </div>
  );
};

export default ChoiceChips;
