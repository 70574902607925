import React from 'react';
import SelectRadioButton from 'components/infra/SelectRadioButton';
import styles from './NewFormUnique.module.scss';
import NewButton from '../NewButton';

type ItemId = number | string;

interface Item {
  text: string;
  id: ItemId;
  feedback?: string;
}

export interface NewFormUniqueProps {
  items: Array<Item>;
  onClickItem(itemId: ItemId): void;
  activeItem?: ItemId;
  correctItem?: ItemId;
  incorrectItem?: ItemId;
  generalFeedback?: string;
  showCorrectItem?: boolean;
  title: string;
  subtitle?: string;
  submitButtonText?: string;
  isButtonDisabled?: boolean;
  onSubmit?(): void;
  onGoBack?(): void;
}

const NewFormUnique: React.FC<NewFormUniqueProps> = ({
  items,
  onClickItem,
  activeItem,
  correctItem,
  incorrectItem,
  generalFeedback,
  showCorrectItem,
  title,
  subtitle,
  submitButtonText,
  onSubmit,
  onGoBack,
  isButtonDisabled = false,
}) => {
  const correctItemSelected = items.find(item => correctItem === item.id);
  return (
    <div className={styles.wrapperUniqueForm}>
      <h1 className={styles.title}>{title}</h1>
      {subtitle && <div dangerouslySetInnerHTML={{__html: subtitle}} />}
      <div className={styles.wrapperAnswers}>
        {items.map((item: Item) => (
          <div key={item.id} className={styles.radioItem}>
            <SelectRadioButton
              checked={activeItem === item.id}
              correct={correctItem === item.id && activeItem === item.id}
              incorrect={incorrectItem === item.id && activeItem === item.id}
              id={item.id.toString()}
              name={item.id.toString()}
              labelText={item.text}
              readOnly
              onClick={(): void => onClickItem(item.id)}
            />
            {item.feedback ? (
              <div className={styles.itemFeedback}>{item.feedback}</div>
            ) : null}
          </div>
        ))}
        {showCorrectItem && correctItemSelected ? (
          <>
            <p>Resposta correta:</p>
            <SelectRadioButton
              checked
              correct={false}
              incorrect={false}
              id={correctItemSelected.id.toString()}
              name=""
              labelText={correctItemSelected.text}
              readOnly
            />
          </>
        ) : null}
        {generalFeedback ? (
          <div className={styles.generalFeedback}>{generalFeedback}</div>
        ) : null}
      </div>
      <div className={styles.wrapperSubmit}>
        {onGoBack && (
          <NewButton kind="secondary" userRole="student" onClick={onGoBack}>
            Voltar
          </NewButton>
        )}
        {onSubmit && (
          <NewButton
            userRole="student"
            onClick={onSubmit}
            disabled={isButtonDisabled}
          >
            {submitButtonText}
          </NewButton>
        )}
      </div>
    </div>
  );
};

export default NewFormUnique;
