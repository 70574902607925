import React, {useEffect, createRef, ReactNode} from 'react';
import NotificationButton, {
  NotificationState,
} from 'components/infra/NotificationButton';
import {UserRole} from '../../../constants';
import styles from './NotificationDropdown.module.scss';

type onClick = (event: React.SyntheticEvent) => void;

export interface NotificationDropdownProps {
  children?: ReactNode;
  notificationState?: NotificationState;
  onClickSeeMore: onClick;
  userRole: UserRole;
  isDropdownOpen: boolean;
  onClickNotificationButton: () => void;
  onClickOutsideDropdown: () => void;
  notificationCount: number;
}

const NotificationDropdown: React.FC<NotificationDropdownProps> = ({
  notificationState,
  children,
  userRole,
  notificationCount,
  onClickSeeMore,
  isDropdownOpen,
  onClickNotificationButton,
  onClickOutsideDropdown,
}) => {
  const wrapperRef = createRef<HTMLDivElement>();

  useEffect(() => {
    function handleClickOutsideLabel(event: any): void {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        onClickOutsideDropdown();
      }
    }

    document.addEventListener('click', handleClickOutsideLabel);

    return (): void => {
      document.removeEventListener('click', handleClickOutsideLabel);
    };
  }, [onClickOutsideDropdown, wrapperRef]);

  return (
    <div className={styles.notificationDropdown}>
      <div ref={wrapperRef}>
        <NotificationButton
          notificationState={isDropdownOpen ? 'showing' : notificationState}
          onClick={onClickNotificationButton}
          userRole={userRole as UserRole}
          notificationCount={notificationCount}
        />
      </div>
      {isDropdownOpen && (
        <div
          className={styles.wrapperDropdownList}
          data-focusid="notificationList"
        >
          <div className={styles.contentDropdown}>
            {children ? (
              <div className={styles.contentNotification}>{children}</div>
            ) : (
              <div data-testid="noContent" className={styles.noContent}>
                <p>Nenhuma notificação</p>
              </div>
            )}
            <div
              className={styles.footerDropdown}
              data-focusid="notificationDropdownFooter"
            >
              <button
                data-testid="notificationHistoryButton"
                className={`${styles.notificationHistoryButton} ${
                  !children ? styles.disabled : styles[userRole]
                }`}
                onKeyDown={onClickSeeMore}
                onClick={onClickSeeMore}
              >
                Ver tudo
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default NotificationDropdown;
