import React, {CSSProperties} from 'react';
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from 'react-circular-progressbar';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import styles from './CircularProgressbar.module.scss';
import 'react-circular-progressbar/dist/styles.css';

export interface RadialSeparatorsProps {
  style: CSSProperties;
  count: number;
}

export interface CircularProgressbarProps {
  score: number;
  onClickInfo?(): void;
  onHoverInfo?(): void;
  size?: number;
  background?: string;
  showInfoIcon?: boolean;
}

const CircularProgressbar = ({
  score,
  onClickInfo,
  onHoverInfo,
  size = 115,
  background = 'white',
  showInfoIcon = true,
}: CircularProgressbarProps): React.ReactElement => {
  const RadialSeparators = (): Array<React.ReactElement> => {
    const turns = 1 / 15;

    return [...Array(24).keys()].map(index => (
      <div
        className={styles.radialSeparator}
        key={index}
        style={{
          transform: `rotate(${index * turns}turn)`,
        }}
      >
        <div style={{background}} />
      </div>
    ));
  };
  return (
    <div className={styles.container}>
      <div className={styles.progressbarWrapper} style={{width: size}}>
        <div className={styles.wrapperText} style={{fontSize: `${size}%`}}>
          <span>{score}%</span>
          <span>iLetrus</span>
        </div>
        <CircularProgressbarWithChildren
          value={score}
          strokeWidth={9}
          styles={buildStyles({
            strokeLinecap: 'butt',
            pathColor: '#5A3E8B',
          })}
        >
          {RadialSeparators()}
        </CircularProgressbarWithChildren>
      </div>

      {showInfoIcon && (
        <button
          className={styles.wrapperIcon}
          onClick={onClickInfo}
          onMouseEnter={onHoverInfo}
        >
          <FontAwesomeIcon color="#BDBDBD" icon="info-circle" />
        </button>
      )}
    </div>
  );
};

export default CircularProgressbar;
