import React, {InputHTMLAttributes, LegacyRef, RefCallback} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import styles from './SelectRadioButton.module.scss';

export interface SelectRadioButtonProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'type'> {
  labelText: string;
  ref?: RefCallback<HTMLInputElement> | LegacyRef<HTMLInputElement>;
  correct?: boolean;
  incorrect?: boolean;
}

const SelectRadioButton: React.FC<SelectRadioButtonProps> = ({
  labelText,
  correct,
  incorrect,
  ...otherProps
}) => {
  return (
    <label
      id={otherProps.id}
      className={`${styles.inputContainer}
        ${otherProps.checked ? styles.checked : ''}
        ${correct ? styles.correct : ''}
        ${incorrect ? styles.incorrect : ''}
        `}
    >
      <div id={otherProps.id} className={styles.radio}>
        <input type="radio" {...otherProps} />

        {labelText && <span>{labelText}</span>}
      </div>
      {(correct || incorrect) && (
        <div
          className={`${correct ? styles.iconCorrect : styles.iconIncorrect}`}
        >
          <FontAwesomeIcon
            size="lg"
            icon={correct ? 'check-circle' : 'times-circle'}
          />
        </div>
      )}
    </label>
  );
};

export default SelectRadioButton;
