import NotificationItem, {
  NotificationItemProps,
} from 'components/infra/NotificationItem';
import React from 'react';
import {UserRole} from '../../../constants';
import styles from './NotificationList.module.scss';

export interface NotificationListProps {
  items: NotificationItemProps[];
  userRole?: UserRole;
}

const NotificationList: React.FC<NotificationListProps> = ({
  items,
  userRole = 'anonymous',
}) => (
  <div className={styles.wrapper}>
    {items &&
      items.map((item, index) => (
        <NotificationItem
          key={item.id}
          userRole={userRole}
          handleClick={item.handleClick}
          {...item}
          data-focusid={`notificationItem-${index}`}
          withHeaderIcon={item.withHeaderIcon}
        />
      ))}
  </div>
);

export default NotificationList;
