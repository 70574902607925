import React from 'react';
import styles from './Label.module.scss';

export type LabelTheme =
  | 'default'
  | 'inverted'
  | 'error'
  | 'success'
  | 'warning';

export interface LabelProps {
  text: string;
  className?: string;
  theme?: LabelTheme;
}

function Label({text, className, theme}: LabelProps): JSX.Element {
  return (
    <div
      className={`${styles.container} ${className} ${theme && styles[theme]}`}
    >
      <strong>{text}</strong>
    </div>
  );
}

export default Label;
