import React from 'react';
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from '@fortawesome/react-fontawesome';
import {UserRole} from '../../../constants';
import styles from './SideBar.module.scss';

const NOTIFICATION_BELL_COUNT_LIMIT = 99;

export interface MenuItem {
  label: string;
  icon: FontAwesomeIconProps;
  onClick?: () => void;
  active?: boolean;
  notificationCount?: number;
}

export type Theme = 'dark' | 'light';

export type SideBarProps = {
  topMenu: MenuItem[];
  bottomMenu?: MenuItem[];
  closed: boolean;
  theme?: Theme;
  userRole?: UserRole;
};

const SideBar: React.FC<SideBarProps> = ({
  topMenu,
  bottomMenu,
  closed,
  theme = 'dark',
  userRole = 'anonymous',
}) => {
  return (
    <div
      data-testid="sidebarContainer"
      className={`${styles.wrapper} ${styles[theme]} ${styles[userRole]} ${
        closed ? styles.closedContainer : ''
      } ${bottomMenu ? styles.bottomMenu : ''}`}
    >
      <div className={styles.container}>
        {topMenu?.map(
          ({icon, label, onClick, active, notificationCount}, i: number) => (
            <div
              key={`menu-item-${label}`}
              onClick={onClick}
              onKeyPress={onClick}
              className={styles.item}
              tabIndex={i}
              role="link"
            >
              <div className={`${styles.icon} ${active ? styles.active : ''}`}>
                {notificationCount && (
                  <span
                    data-testid="notification-count"
                    className={`${styles.circle}`}
                  >
                    {notificationCount > NOTIFICATION_BELL_COUNT_LIMIT
                      ? '99'
                      : notificationCount}
                  </span>
                )}
                <FontAwesomeIcon
                  {...{
                    size: 'lg',
                    ...icon,
                  }}
                />
              </div>
              <span className={`${styles.text} ${active ? styles.active : ''}`}>
                {label}
              </span>
            </div>
          ),
        )}
      </div>
      {bottomMenu && (
        <div className={styles.container}>
          <hr className={styles.divider} />
          {bottomMenu?.map(({icon, label, onClick, active}, i: number) => (
            <div
              key={`menu-item-${label}`}
              onClick={onClick}
              onKeyPress={onClick}
              className={styles.item}
              tabIndex={i}
              role="link"
            >
              <div className={`${styles.icon} ${active ? styles.active : ''}`}>
                <FontAwesomeIcon
                  {...{
                    size: 'lg',
                    ...icon,
                  }}
                />
              </div>
              <span className={`${styles.text} ${active ? styles.active : ''}`}>
                {label}
              </span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default SideBar;
