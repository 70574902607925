/* eslint-disable react-hooks/exhaustive-deps */
import {Button} from 'components';
import React, {useState} from 'react';
import styles from './TableShowMore.module.scss';

interface RequestParams {
  limit: number;
}

export interface TableShowMoreProps {
  totalItems: number;
  onClick?(requestParams: RequestParams): void;
}

function TableShowMore({totalItems, onClick}: TableShowMoreProps): JSX.Element {
  const [hasBeenClicked, setHasBeenClicked] = useState(false);
  function handleShowMoreClick() {
    onClick?.({limit: totalItems});

    setHasBeenClicked(true);
  }

  return hasBeenClicked ? (
    <></>
  ) : (
    <tr>
      <td colSpan={100} className={styles.showMore}>
        <Button
          variant="secondary"
          text="Ver todos"
          leftIcon={{icon: 'chevron-down'}}
          onClick={() => handleShowMoreClick()}
        />
      </td>
    </tr>
  );
}

export default TableShowMore;
