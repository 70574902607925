import React, {ReactNode} from 'react';
import {UserRole} from '../../../constants';
import styles from './Navbar.module.scss';

export interface NavbarProps {
  leftComponent?: ReactNode;
  centerComponent?: ReactNode;
  rightComponent?: ReactNode;
  userRole?: UserRole;
}

const Navbar: React.FC<NavbarProps> = ({
  leftComponent,
  centerComponent,
  rightComponent,
  userRole = 'anonymous',
}) => {
  return (
    <header className={`${styles.container} ${styles[userRole]}`}>
      <div className={styles.content}>
        <div className={styles.sideComponent}>{leftComponent}</div>
        <div className={styles.centerComponent}>{centerComponent}</div>
        <div className={styles.sideComponent}>{rightComponent}</div>
      </div>
    </header>
  );
};

export default Navbar;
