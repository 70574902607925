import React from 'react';
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from '@fortawesome/react-fontawesome';
import styles from './Button.module.scss';

export type ButtonVariant = 'primary' | 'secondary' | 'tertiary';

export interface ButtonProps {
  text?: string;
  leftIcon?: FontAwesomeIconProps;
  rightIcon?: FontAwesomeIconProps;
  disabled?: boolean;
  loading?: boolean;
  divider?: boolean;
  onClick?: React.MouseEventHandler;
  variant: ButtonVariant;
  dividerColor?: string;
  textColor?: string;
  backgroundColor?: string;
  borderColor?: string;
  boxShadow?: string;
  circular?: boolean;
  size?: 'small' | 'medium' | 'large';
  type?: 'button' | 'submit' | 'reset';
}

function Button({
  text,
  leftIcon,
  rightIcon,
  divider,
  disabled = false,
  loading = false,
  circular = false,
  onClick,
  dividerColor,
  textColor,
  backgroundColor,
  borderColor,
  boxShadow,
  variant = 'primary',
  size = 'medium',
  type = 'button',
}: ButtonProps): JSX.Element {
  return (
    <button
      data-testid="button"
      className={`
        ${styles.button}
        ${styles[size]}
        ${styles[variant]}
        ${circular && styles.circular}
        ${loading && styles.loading}
        ${disabled ? styles.disabled : ''}
      `}
      style={disabled ? {} : {backgroundColor, borderColor, boxShadow}}
      type={type}
      onClick={onClick}
      disabled={loading || disabled}
    >
      {leftIcon && !loading && (
        <FontAwesomeIcon
          className={`
            ${styles.leftIcon}
            ${styles[size]}
            ${!text && styles.noMarginRight}
            ${!leftIcon.color ? styles[variant] : ''}
            ${disabled ? styles.disabled : ''}
          `}
          {...leftIcon}
        />
      )}
      {loading && (
        <FontAwesomeIcon
          className={`
            ${styles.loading}
            ${!text && styles.noMarginRight}
            ${styles[size]}
          `}
          icon={['fad', 'loader']}
          spin
        />
      )}
      <span
        className={`
          ${styles.text} 
          ${styles[variant]} 
          ${styles[size]} ${disabled ? styles.disabled : ''}
        `}
        style={disabled ? {} : {color: textColor}}
      >
        {text}
      </span>
      {divider && !loading && (
        <hr
          data-testid="button-divider"
          className={`
            ${styles.divider}
            ${styles[size]} 
            ${styles[variant]}
            ${disabled ? styles.disabled : ''}
          `}
          style={disabled ? {} : {borderColor: dividerColor}}
        />
      )}
      {rightIcon && !loading && (
        <FontAwesomeIcon
          className={`
            ${styles.rightIcon} 
            ${styles[size]}
            ${!rightIcon.color ? styles[variant] : ''} 
            ${disabled ? styles.disabled : ''}
          `}
          {...rightIcon}
        />
      )}
    </button>
  );
}

export default Button;
