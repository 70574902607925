import React, {useEffect, useState} from 'react';
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from '@fortawesome/react-fontawesome';
import styles from './Tag.module.scss';

export type TagVariant = 'readOnly' | 'dismissible' | 'operational';

export interface TagProps {
  text: string;
  variant: TagVariant;
  leftIcon?: FontAwesomeIconProps;
  mainColor?: string;
  backgroundColor?: string;
  onClickCloseButton?: () => void;
  onClickTag?: () => void;
  textColor?: string;
  selected?: boolean;
  tooltip?: boolean;
  boxShadow?: string;
}

const Tag: React.FC<TagProps> = ({
  text,
  variant,
  leftIcon,
  onClickCloseButton,
  onClickTag,
  mainColor,
  backgroundColor,
  textColor,
  selected,
  tooltip,
  boxShadow,
}) => {
  const [isSelected, setIsSelected] = useState(selected);

  useEffect(() => {
    setIsSelected(selected);
  }, [selected]);

  function onClickLabel() {
    if (variant === 'dismissible') {
      setIsSelected(!isSelected);
    }

    if (onClickTag) {
      onClickTag();
    }
  }

  function onClickClose() {
    if (onClickCloseButton) {
      onClickCloseButton();
    }

    setIsSelected(!isSelected);
  }

  const getBackgroundColor = isSelected ? mainColor : backgroundColor;
  function getColor() {
    if (textColor) {
      return textColor;
    }
    if (isSelected) {
      return 'white';
    }
    return mainColor;
  }

  return (
    <button
      onClick={onClickLabel}
      data-testid="tag"
      type="button"
      className={`
        ${styles.container}
        ${styles[variant]}
        ${isSelected && styles.selected}
      `}
      style={{
        backgroundColor: getBackgroundColor,
        borderColor: mainColor,
        color: getColor(),
        boxShadow,
      }}
    >
      {leftIcon && (
        <span data-testid="leftIcon">
          <FontAwesomeIcon size="sm" color={getColor()} {...leftIcon} />
        </span>
      )}
      <p className={styles.tagText}>
        {text}
        {tooltip && <span className={styles.tagTooltipText}>{text}</span>}
      </p>
      {variant === 'dismissible' && isSelected && (
        <button
          data-testid="closeButton"
          onClick={onClickClose}
          className={styles.closeButton}
          type="button"
        >
          <FontAwesomeIcon
            data-testid="closeIcon"
            size="sm"
            color={getColor()}
            icon={['fas', 'times']}
          />
        </button>
      )}
    </button>
  );
};

export default Tag;
