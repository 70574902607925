import React, {ReactNode} from 'react';
import styles from './TabList.module.scss';
import TabItem from './TabItem';
import {UserRole} from '../../../constants';

interface DataObject {
  name: ReactNode;
  id: number;
  disabled?: boolean;
}

export interface TabListProps {
  data: Array<DataObject>;
  handleTabChange: (id: number) => void;
  tabActive: number;
  userRole?: UserRole;
  withLine?: boolean;
  zeroheight?: boolean;
}

const TabList: React.FC<TabListProps> = ({
  data,
  handleTabChange,
  tabActive,
  userRole = 'anonymous',
  withLine,
  zeroheight,
}) => {
  const onTabChange = (id: number): void => {
    handleTabChange(id);
  };

  return (
    <div className={styles.tabs}>
      <div
        className={`${styles.tabMenus} ${withLine ? styles.border : ''} ${
          zeroheight ? styles.zeroHeight : ''
        }`}
      >
        {data.map(({name, id, disabled}) => (
          <div
            className={styles.tabItemWrapper}
            key={id}
            data-testid="tabAmount"
          >
            <TabItem
              userRole={userRole}
              active={tabActive === id}
              handleClick={(): void => onTabChange(id)}
              key={id}
              text={name}
              zeroheightStyle={zeroheight}
              disabled={disabled}
            />
            <div
              className={`${
                tabActive === id
                  ? styles.belowItemBarActive
                  : styles.belowItemBar
              } ${styles[userRole]}`}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default TabList;
