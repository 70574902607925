import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import styles from './TableContainer.module.scss';

export interface TableContainerProps {
  children: JSX.Element | JSX.Element[];
  className?: string;
  loading?: boolean;
}

function TableContainer({
  children,
  className,
  loading,
}: TableContainerProps): JSX.Element {
  return (
    <table className={` ${styles.wrapper} ${className}`}>
      {children}
      {loading && (
        <tbody className={styles.loading}>
          <tr data-testid="table-loader">
            <td>
              <FontAwesomeIcon icon="spinner" spin size="4x" />
            </td>
          </tr>
        </tbody>
      )}
    </table>
  );
}

export default TableContainer;
