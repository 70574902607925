import React from 'react';
import styles from './LineWordCounter.module.scss';

export interface LineWordCounterProps {
  amountOfLines?: number;
  amountOfWords?: number;
  sufficientAmountOfWords?: boolean;
}

const LineWordCounter: React.FC<LineWordCounterProps> = ({
  amountOfLines,
  amountOfWords,
  sufficientAmountOfWords,
}) => {
  const getWordCounterClass = () => {
    if (sufficientAmountOfWords === false) {
      return styles.insufficient;
    }

    if (sufficientAmountOfWords === true) {
      return styles.sufficient;
    }

    return '';
  };

  return (
    <div className={styles.wrapper}>
      {amountOfLines !== undefined ? (
        <div className={styles.lines}>
          <span>{amountOfLines}</span>{' '}
          <strong>{amountOfLines === 1 ? 'LINHA' : 'LINHAS'}</strong>
        </div>
      ) : null}
      {amountOfWords !== undefined ? (
        <div
          className={`
          ${styles.words} 
          ${getWordCounterClass()}
        `}
          data-testid="words"
        >
          <span>{amountOfWords}</span>{' '}
          <strong>{amountOfWords === 1 ? 'PALAVRA' : 'PALAVRAS'}</strong>
        </div>
      ) : null}
    </div>
  );
};

export default LineWordCounter;
