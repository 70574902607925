import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {UserRole} from '../../../constants';
import styles from './NotificationItem.module.scss';

export interface NotificationItemProps {
  id: number;
  title?: string;
  description: string;
  time: string;
  opened: boolean;
  userRole?: UserRole;
  withHeaderIcon?: boolean;
  handleClick?: () => void;
}

const NotificationItem: React.FC<NotificationItemProps> = ({
  id,
  title,
  description,
  time,
  opened,
  withHeaderIcon = false,
  handleClick,
  userRole = 'anonymous',
}) => (
  <div
    className={`${styles.item} ${styles[userRole]} ${
      opened ? styles.opened : ''
    }`}
    onClick={handleClick}
    onKeyPress={handleClick}
    role="button"
    tabIndex={id}
  >
    <div className={styles.content}>
      <header>
        <b className={styles.title}>{title}</b>

        {withHeaderIcon && (
          <span
            className={styles.iconWrapper}
            data-testid="notification-item-icon"
          >
            <FontAwesomeIcon icon="external-link" size="sm" color="#121212" />
          </span>
        )}
      </header>

      <p className={styles.description}>{description}</p>
      <span className={styles.time}>{time}</span>
    </div>
  </div>
);

export default NotificationItem;
