/* eslint-disable react-hooks/exhaustive-deps */
import {Button} from 'components';
import React, {useEffect, useRef, useState} from 'react';
import styles from './TablePagination.module.scss';

interface RequestParams {
  limit: number;
  offset: number;
}

export interface TablePaginationProps {
  totalItems: number;
  itemsPerPage?: number;
  onPageChange?(requestParams: RequestParams): void;
}

function TablePagination({
  totalItems,
  itemsPerPage = 20,
  onPageChange,
}: TablePaginationProps): JSX.Element {
  const [currentPage, setCurrentPage] = useState(1);
  const totalOfPages = Math.ceil(totalItems / itemsPerPage);
  const isFirstRender = useRef(true);

  useEffect(() => {
    if (!isFirstRender.current) {
      const offset = currentPage * itemsPerPage;

      onPageChange?.({limit: itemsPerPage, offset});
    } else {
      isFirstRender.current = false;
    }
  }, [currentPage]);

  function handlePageChange(event: React.ChangeEvent<HTMLInputElement>) {
    const {value} = event.target;

    if (Number(value) >= 1 && Number(value) <= totalOfPages) {
      setCurrentPage(Number(value));
    }
  }

  function handleNextPageClick() {
    setCurrentPage(currentPage + 1);
  }

  function handlePreviousPageClick() {
    setCurrentPage(currentPage - 1);
  }

  return (
    <tfoot>
      <tr>
        <td colSpan={100} className={styles.tablePagination}>
          <Button
            variant="tertiary"
            text="Anterior"
            backgroundColor="transparent"
            disabled={currentPage === 1}
            leftIcon={{icon: 'chevron-left'}}
            onClick={() => handlePreviousPageClick()}
          />
          <input
            type="number"
            min={1}
            step={1}
            max={totalItems}
            value={currentPage}
            onChange={handlePageChange}
            data-testid="pagination-input"
          />
          de {totalOfPages}
          <Button
            variant="tertiary"
            text="Próxima"
            backgroundColor="transparent"
            rightIcon={{icon: 'chevron-right'}}
            onClick={() => handleNextPageClick()}
            disabled={currentPage === totalOfPages}
          />
        </td>
      </tr>
    </tfoot>
  );
}

export default TablePagination;
