import React, {useState, ReactNode} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import styles from './AccordeonEngagement.module.scss';

type onClick = (event: React.SyntheticEvent) => void;

export type ItemProps = {
  id: string | number;
  text?: string;
  level: string | number;
  onClickItem: onClick;
};

export interface AccordeonEngagementProps {
  items: ItemProps[];
  title: string;
  students: number;
  colorIcon: string;
}

export const AccordeonEngagement: React.FC<AccordeonEngagementProps> = ({
  items,
  title,
  colorIcon,
  students,
}) => {
  const [isListVisible, setIsListVisible] = useState<boolean>(false);
  const [listItems] = useState<Array<ItemProps>>(items);
  const toggleListVisibility = (): void => {
    setIsListVisible(!isListVisible);
  };

  return (
    <div className={`${styles.wrapper} ${isListVisible ? styles.open : ''}`}>
      <button
        data-testid="container"
        className={`${styles.title} ${isListVisible ? styles.open : ''}`}
        onClick={(): void => toggleListVisibility()}
      >
        <span className={styles.titleContent}>
          <div className={styles.right}>
            <div className={styles.left}>
              <FontAwesomeIcon icon="user" size="2x" color={colorIcon} />
            </div>
            <div className={styles.number}>{students}</div>
            <div className={styles.titleText}>{title}</div>
          </div>
          <div className={styles.right}>
            <FontAwesomeIcon
              color="#414449"
              icon={isListVisible ? 'sort-up' : 'sort-down'}
            />
          </div>
        </span>
      </button>
      {isListVisible && (
        <div className={styles.wrapperDropdownList}>
          <ul className={styles.dropdownMenuList}>
            {listItems.map(
              (item: ItemProps): ReactNode => (
                <li
                  role="menuitem"
                  tabIndex={0}
                  key={item.id}
                  className={styles.dropdownMenuItem}
                >
                  <div className={styles.textItem}>{item.text}</div>
                  <div className={styles.buttonSee}>
                    <div className={styles.level}>Nv {item.level}</div>
                    <button
                      data-testid={item.id}
                      className={styles.buttonSee}
                      onClick={(event): void => item.onClickItem(event)}
                    >
                      <FontAwesomeIcon icon="eye" />
                      <div className={styles.seeText}>Ver</div>
                    </button>
                  </div>
                </li>
              ),
            )}
          </ul>
        </div>
      )}
    </div>
  );
};

export default AccordeonEngagement;
