import Container from './TableContainer';
import Header from './TableHeader';
import Pagination from './TablePagination';
import ShowMore from './TableShowMore';

const Table = {
  Container,
  Header,
  Pagination,
  ShowMore,
};

export default Table;
