import React from 'react';
import {IconProp} from '@fortawesome/fontawesome-svg-core';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import styles from './Link.module.scss';

export type LinkProps = {
  text: string;
  icon?: IconProp;
  onClick: () => void;
};

const Link: React.FC<LinkProps> = ({icon, text, onClick}) => (
  <div onClick={onClick} onKeyPress={onClick} role="link" tabIndex={0}>
    <p
      className={`
        ${styles.text} 
    `}
    >
      {icon ? (
        <span className={styles.icon}>
          <FontAwesomeIcon icon={icon} />
        </span>
      ) : null}
      {text}
    </p>
  </div>
);

export default Link;
