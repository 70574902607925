/* eslint-disable react/jsx-no-bind */
import React, {
  ForwardedRef,
  SelectHTMLAttributes,
  forwardRef,
  useState,
  useRef,
  useEffect,
} from 'react';
import Calendar from 'react-calendar';
import {Value} from 'react-calendar/dist/cjs/shared/types';
import {toISOString} from 'utils/dateConversion';
import styles from './DateInput.module.scss';

export interface DateInputProps
  extends SelectHTMLAttributes<HTMLSelectElement> {
  minDate?: Date;
  maxDate?: Date;
  selectRange?: boolean;
}

/**
 * Selects a date single date or a range using a calendar.
 *
 * This component passes a ref to the select element to the level up,
 * so it can be used with react-hook-form or other form libraries.
 *
 * Using the ref of a SelectHTMLElement, it is possible to retrieve
 * an array with the [startDate, endDate], that is update only after the full
 * range is selected.
 *
 * @param {Value} values - An array of two Date objects representing start and end dates.
 * @return dates - an array with [startDate, endDate] on the exposed select ref value.
 */
function DateInput(
  {minDate, maxDate, selectRange, ...inputProps}: DateInputProps,
  selectRef: ForwardedRef<HTMLSelectElement>,
): JSX.Element {
  const calendarRef = useRef<HTMLDivElement>(null);
  const endDateRef = useRef<HTMLOptionElement>(null);
  const startDateRef = useRef<HTMLOptionElement>(null);
  const [selectedDates, setSelectedDates] = useState(['', '']);

  useEffect(() => {
    focusOnInput();
  }, [selectedDates]);

  function focusOnInput() {
    // necessary to keep the value updated on react-hook-form integration
    const hiddenSelect = calendarRef?.current
      ?.previousElementSibling as HTMLSelectElement;

    startDateRef.current?.setAttribute('selected', 'true');
    endDateRef.current?.setAttribute('selected', 'true');

    const event = new Event('change', {bubbles: true});
    hiddenSelect?.dispatchEvent(event);
  }

  function formatCalenderTitle(_: string | undefined, date: Date) {
    const monthName = date.toLocaleString('pt-BR', {month: 'long'});
    const year = date.getFullYear();

    return `${monthName} ${year}`;
  }

  function handleDateChange(values: Value) {
    if (selectRange) {
      const [startDate, endDate] = values as [Date, Date];

      setSelectedDates([toISOString(startDate), toISOString(endDate)]);
    } else {
      setSelectedDates([toISOString(values as Date), '']);
    }
  }

  return (
    <>
      <select
        multiple
        aria-hidden
        ref={selectRef}
        className={styles.hiddenInput}
        {...inputProps}
      >
        <option
          ref={startDateRef}
          value={selectedDates[0]}
          data-testid="startDate"
        />
        <option
          ref={endDateRef}
          value={selectedDates[1]}
          data-testid="endDate"
        />
      </select>

      <Calendar
        selectRange={selectRange}
        locale="pt-br"
        minDate={minDate}
        maxDate={maxDate}
        prev2Label={null}
        next2Label={null}
        inputRef={calendarRef}
        onChange={handleDateChange}
        className={styles.calendar}
        formatMonthYear={formatCalenderTitle}
        goToRangeStartOnSelect
      />
    </>
  );
}

export default forwardRef(DateInput);
