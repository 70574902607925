import React, {ForwardedRef, InputHTMLAttributes, forwardRef} from 'react';
import styles from './Checkbox.module.scss';

export interface CheckboxProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'type'> {
  labelText?: string;
}

function Checkbox(
  {labelText, id, ...otherProps}: CheckboxProps,
  ref: ForwardedRef<HTMLInputElement>,
): JSX.Element {
  return (
    <div className={`${styles.inputContainer} ${!labelText && styles.noLabel}`}>
      <input id={id} type="checkbox" ref={ref} {...otherProps} />

      {labelText && <label htmlFor={id}>{labelText}</label>}
    </div>
  );
}

export default forwardRef(Checkbox);
