import React, {useEffect, useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import styles from './NewFormMultiple.module.scss';
import NewButton from '../NewButton';
import Checkbox from '../Checkbox';

interface Item {
  text: string;
  id: number;
  feedback?: string;
}

interface EnrichedItem extends Item {
  isCorrect?: boolean;
  hasCorrectAnswer: boolean;
  isSelected: boolean;
}

export interface NewFormMultipleProps {
  items: Item[];
  onClickItem(itemId: number): void;
  selectedItems: number[];
  correctItems?: number[];
  showCorrectAnswer?: boolean;
  generalFeedback?: string;
  title?: string;
  description?: string;
  submitButtonText?: string;
  selectionDisabled: boolean;
  onSubmit?(): void;
  onGoBack?(): void;
}

const NewFormMultiple: React.FC<NewFormMultipleProps> = ({
  items,
  onClickItem,
  selectedItems,
  correctItems,
  showCorrectAnswer,
  generalFeedback,
  title,
  description,
  submitButtonText,
  selectionDisabled,
  onSubmit,
  onGoBack,
}) => {
  const [itemsWithStatus, setItemsWithStatus] = useState<EnrichedItem[]>([]);

  useEffect(() => {
    const enrichedItems = items.map<EnrichedItem>(item => {
      const hasCorrectAnswer = !!correctItems?.length;
      const isCorrect = hasCorrectAnswer
        ? correctItems?.some(correctItem => correctItem === item.id)
        : undefined;

      return {
        ...item,
        hasCorrectAnswer,
        isCorrect,
        isSelected: selectedItems.some(
          selectedItem => selectedItem === item.id,
        ),
      };
    });

    setItemsWithStatus(enrichedItems);
  }, [items, selectedItems, correctItems]);

  const handleItemClick = (
    event: React.ChangeEvent<HTMLInputElement>,
    itemId: number,
  ) => {
    if (selectionDisabled) {
      event.preventDefault();
    } else {
      onClickItem(itemId);
    }
  };

  return (
    <form className={styles.wrapper}>
      <header>
        <h1>{title}</h1>
        {description && <div dangerouslySetInnerHTML={{__html: description}} />}
      </header>

      <fieldset className={styles.answerList}>
        {itemsWithStatus.map(item => (
          <div key={item.id}>
            <div
              className={styles.answer}
              data-selected={item?.isSelected}
              data-correct={item?.isCorrect}
              data-testid="item"
            >
              <Checkbox
                id={String(item.id)}
                name={String(item.id)}
                labelText={item.text}
                checked={item?.isSelected}
                onChange={event => handleItemClick(event, item.id)}
              />

              {item.isSelected && item.hasCorrectAnswer && (
                <div className={styles.icon} data-correct={item?.isCorrect}>
                  <FontAwesomeIcon icon={item?.isCorrect ? 'check' : 'times'} />
                </div>
              )}
            </div>

            {item.feedback && (
              <p className={styles.itemFeedback}>{item.feedback}</p>
            )}
          </div>
        ))}

        {showCorrectAnswer && (
          <div className={styles.correctAnswer}>
            <p>Resposta(s) correta(s):</p>
            {itemsWithStatus.map(
              item =>
                item?.isCorrect && (
                  <fieldset
                    className={styles.answer}
                    key={item.id}
                    data-testid="correct-answer"
                  >
                    <Checkbox
                      name="correctAnswer"
                      labelText={item.text}
                      defaultChecked
                      onClick={event => event.preventDefault()}
                    />
                  </fieldset>
                ),
            )}
          </div>
        )}

        {generalFeedback && (
          <p className={styles.generalFeedback}>{generalFeedback}</p>
        )}
      </fieldset>

      <div className={styles.wrapperSubmit}>
        {onGoBack && (
          <NewButton kind="secondary" userRole="student" onClick={onGoBack}>
            Voltar
          </NewButton>
        )}
        {onSubmit && (
          <NewButton
            userRole="student"
            onClick={onSubmit}
            disabled={!selectedItems?.length}
          >
            {submitButtonText}
          </NewButton>
        )}
      </div>
    </form>
  );
};

export default NewFormMultiple;
